.container {
    display: flex;
    align-items: stretch;
    min-height: 100vh;
    background: #1D1F23;
}

.content {
    background: linear-gradient(0deg, #F7F7F5, #F7F7F5);
    padding: 30px 41px !important;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 80vh;
    border-radius: 24px 24px 0 0;
}