.container {
  font-family: 'graphic';
  font-weight: 400;
  color: #333;
  display: flex;
  align-items: center;
  cursor: pointer;

  & > span {
    margin-right: 16px;
  }
  --height: 22px;
  --width: 42px;
}

.checkbox {
  cursor: pointer;
}

.checkbox input {
  width: 0;
  height: 0;
  margin: 0;
  position: absolute;
  opacity: 0;
}

.checkbox span {
  display: inline-block;
  position: relative;
  width: var(--width);
  height: var(--height);
  border-radius: var(--width);
  padding: 2px;
  background-size: 200%;
  background-color: #CCC;
}

.checkbox span:before {
  content: '';
  position: absolute;
  inset: 0;
  border: 2px solid transparent;
  background-clip: padding-box;
  background-color: #FFF;
  border-radius: var(--width);
}

.checkbox span:after {
  content: '';
  display: block;
  width: var(--height);
  height: 20px;
  background-color: #CCC;
  border-radius: 50%;
  position: relative;
  transition: right .2s ease-in-out;
  right: -2px;
  top: 1px;
  background-size: 500%;
}

.checkbox input:checked + span {
  background-color: #73D389;
}

.checkbox input:checked + span:before {
  background-color: #73D389;
}

.checkbox input:checked + span:after {
  background-color: #fff;
  right: calc(-98% + var(--height));
  animation: slidebg2 16s linear infinite;
}


@keyframes slidebg {
  to {
    background-position-x: 200%;
  }
}

@keyframes slidebg2 {
  to {
    background-position-x: -500%;
  }
}
