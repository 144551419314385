.container {
  height: 100%;
  background: #FFFFFF;
  border-radius: 24px;
  padding: 24px;
}

.header {
  margin-bottom: 21px;
}

.headerTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;

  & > div:nth-child(1) {
    width: 60%;
    max-width: 500px;
  }

  & > div:nth-child(2) {
    display: flex;
    min-width: 270px;
    button {
      margin-left: 20px;
      white-space: nowrap;
    }
  }
}

.headerBottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  h6 {
    font-family: 'graphic';
    color: #1D1F23;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: -0.02em;
    margin: 0 0 16px;
  }
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(#fff, .3);
  z-index: 99;
}

.modal {
  width: 589px;
  min-height: 590px;
  background: #FFFFFF;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  position: relative;
}

.closeModal{
  z-index: 2;
  cursor: pointer;
  position: absolute;
  top:28px;
  right: 28px;
}

.modalContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px;
  box-sizing: border-box;
}

.modalContainer .p{
  font-weight: 500;
  font-size: 18px;
}

.modalContainer .span{
  font-family: 'graphic';
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: rgba(29, 31, 35, 0.55);
  opacity: 0.6;
}

.bonuses {
  padding-right: 17px;
  padding-left: 17px;

  button {
    margin: 50px auto 0;
  }
}

.bonusesHeader {
  color: #1D1F23;
  font-weight: 300;
  font-size: 18px;
  display: flex;
  margin-bottom: 24px;

  svg {
    flex-shrink: 0;
    margin-top: 4px;
  }
  p {
    padding: 0;
    margin: 0 0 0 10px;
  }
}

.subTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #333333;
  margin: 0 0 14px 0;
}

.inputBonus {
  color: #73D389;
  margin-top: 20px;
  background-color: #73D389;
  background-image: linear-gradient(to bottom, #73D389, #4EB78B);
  -webkit-background-clip: text;

  font-weight: 500;
  font-size: 48px;
  outline: none;
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  border: none;
}

.bonusInput {
  width: 491px;
  height: 75px;
  border: 1px solid #E7E7E6;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  color: #333333;
}

.green {
  border-color: #73D389;
  margin-bottom: 10px;
}

.cash {
  span {
    color: #333333;
    opacity: 0.6;
    font-size: 16px;
  }
  b {
    font-weight: 500;
    font-size: 16px;
    color: #333333;
  }
}

.bonusesResult {
  & > div {
    display: flex;
    padding: 12px 39px;
    box-sizing: border-box;
    & > span {
      font-size: 22px;
      line-height: 26px;
      color: #1D1F23;
      display: inline-block;
      &:nth-child(2) {
        max-width: 298px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &:first-child {
        width: 50%;
        margin-right: 39px;
      }
    }
    b {
      font-size: 22px;
      line-height: 26px;
      color: #1D1F23;
      margin-right: 39px;
      width: 50%;
    }
  }
}

.bonusesResultItem {
  margin-top: 20px;
  padding: 26px 39px !important;
  left: 484px;
  top: 362px;
  background: rgba(#4EB78B, .1);
  border-radius: 12px;
}

.textArea {
  height: 130px;
  width: 100%;
  box-sizing: border-box;
  font-family: graphic, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: black;
  padding: 11px 20px;
  border: 1px solid #97D6B9;
  border-radius: 6px;
  outline: none;
  resize: none;
}

.btn {
  text-align: center;
  justify-content: center;
  width: 260px;
  height: 50px;
}

.greenBtn {
  font-family: 'graphic';
  font-size: 16px;
  color: #73D389;
  background: -webkit-linear-gradient(#73D389, #4EB78B);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
