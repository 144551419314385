.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 9px;
}

.container h1 {
    margin: 0;
    font-family: graphic, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #333333;
    border-bottom: 1px solid #ECECEC;
    width: 100%;
    padding: 0 0 18px 0;
}

.numbers{
    margin-top: 21px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}