.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 9px;
}

.container h1 {
    margin: 0;
    font-family: graphic, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #333333;
    border-bottom: 1px solid #ECECEC;
    width: 100%;
    padding: 0 0 18px 0;
}

.datesPickers {
    margin-top: 24px;
    display: flex;
    align-items: center;
}

.numbersWrapper {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.numbers {
    display: flex;
    flex-direction: column;
}

.numbers:first-child {
    border-right: 1px dashed rgba(53, 70, 85, 0.17);
    padding-right: 1.6vw;
}
.numbers:nth-child(2) {
    border-right: 1px dashed rgba(53, 70, 85, 0.17);
    padding-left: 1.6vw;
}

.numbers:last-child {
    margin-left: 1.6vw;
}

.numbers h2 {
    font-family: graphic, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #1D1F23;
}

.chartsWrapper {
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chart:first-child {
    margin-right: 100px;
}

.chart:last-child {
    margin-left: 100px;
}
