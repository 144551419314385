.container {
  background: #FFFFFF;
  border-radius: 24px;
  position: relative;
  padding: 0 0 0 50px;
}

.dataWrapper {
  display: flex;
  flex-wrap: wrap;
}

.dataWrapperColumn {
  max-width: 500px;
  &:first-child {
    margin-right: 5px;
  }
}

.dataItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 430px;
  margin-bottom: 8px;
  &:first-child {
    margin-right: 55px;
  }
}

.p {
  white-space: nowrap;
  margin: 0;
  font-family: sf, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.125em;
  line-height: 21px;
  color: #1D1F23;
  flex-shrink: 0;
  flex-grow: 0;
  width: 160px;
}

.span {
  font-family: sf, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125em;
  line-height: 21px;
  color: #1D1F23;
  background: #F6F6F5;
  border-radius: 6px;
  padding: 19px 0 19px 34px;
  max-width: 220px;
  width: 100%;
  margin: 0;
  flex-shrink: 0;
  flex-grow: 0;
}

.clients {
  margin-top: 83px;
}
.h2 {
  margin: 0 0 25px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #1D1F23;
}