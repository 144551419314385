.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 9px;
}

.container h1 {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 0;
  font-family: graphic, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #333333;
  width: 100%;
  padding: 0 0 18px 0;
  border-bottom: 1px solid #ECECEC;
}

.table {
  font-family: ' graphic';
  td {
    font-weight: 400;
    font-size: 14px;
  }
}

.period {
  span {
    display: block;
  }
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 101px;
  height: 29px;
  border-radius: 3px;
  background: #FA6262;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.2px;
  color: #FFFFFF;
}

.statusActive {
  background: #73D389;
}
