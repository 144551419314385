.container {
  border-bottom: 1px solid #E4E7EB;
  padding-bottom: 24px;
  margin-bottom: 24px;
}

.block {
  margin-top: 17px;

  label {
    display: flex;
  }

  .block {
    &__title {
      font-family: 'graphic';
      font-size: 18px;
      margin-bottom: 16px;
      span {
        color: tomato;
      }
    }
  }
}