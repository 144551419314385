.container {
  width: 100%;
  border-bottom: 1px solid #E4E7EB;;
  padding-bottom: 12px;
  margin-bottom: 26px;
  min-height: 200px;
}

.header {
  display: flex;
  align-items: center;
}

.rateNumber {
  background: -webkit-linear-gradient(#73D389, #4EB78B);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  font-size: 36px;
}

.reviewsCount {
  font-size: 22px;
  color: #AFAFAB;
}

.body {
  margin-top: 30px;
}

.review {
  display: flex;
  margin-bottom: 12px;
}

.mark {
  font-family: 'graphic';

  span:nth-child(1) {
    font-weight: 500;
    color: #1D1F23;
  }
  span:nth-child(2) {
    color: #AFAFAB;
  }
}

.line {
  margin-left: 17px;
  width: 254px;
  height: 8px;
  background: #F5F5F5;
  border-radius: 32px;
  &:last-child {
    margin-bottom: 0;
  }
  & > div {
    background: linear-gradient(180deg, #73D389 0%, #4EB78B 100%);
    border-radius: 32px;
    height: 100%;
  }
}