.container {
  margin: 85px 65px;
  text-align: center;
  p {
    font-size: 22px;
    line-height: 20px;
    color: #000000;
  }
  svg {
    width: 88px;
    height: 88px;
  }
}

.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  color: #000000;
  margin: 22px auto;
}

.btn {
  margin-top: 78px;
  justify-content: center;
  border: none;
  width: 100%;
  max-width: 325px;
  margin-left: auto;
  margin-right: auto;
}