.container {
  display: flex;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
}

.wrapper {
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  border-radius: 24px;
}

.leftContent {
  width: 100%;
  max-width: 589px;
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  position: relative;
}

.rightContent {
  padding: 32px 8px 32px 0;
  background: #FFFFFF;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
}

.modalContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px;
  box-sizing: border-box;
}

.modalContainer .p, .rightContent .p {
  font-weight: 500;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 12px;
}

.modalContainer .span{
  font-family: 'graphic';
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: rgba(29, 31, 35, 0.55);
  opacity: 0.6;
}

.textArea {
  height: 130px;
  width: 100%;
  box-sizing: border-box;
  font-family: graphic, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: black;
  padding: 11px 20px;
  border: 1px solid #97D6B9;
  border-radius: 6px;
  outline: none;
  resize: none;
}

.btn {
  text-align: center;
  justify-content: center;
  width: 260px;
  height: 50px;
  margin: 0 auto 0;
}

.stopBtn {
  text-align: center;
  justify-content: center;
  width: auto;
  height: 50px;
  margin: 12px auto 0;
}

.greenBtn {
  font-family: 'graphic';
  font-size: 16px;
  color: #73D389;
  background: -webkit-linear-gradient(#73D389, #4EB78B);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.history {
  padding: 0;
  list-style: none;
  margin: 0;
  width: 500px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;

  li {
    border: 1px solid gray;
    padding: 8px;
    transition: 0.3s ease all;
    border-radius: 4px;
    box-sizing: border-box;
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 10px 22px 3fr;
    gap: 8px;
    position: relative;
    cursor: pointer;
    &:first-child {
      border: none;
    }
    p {
      &:last-child {
        text-align: right;
      }
    }

    &:before {
      content: '';
      position: relative;
      width: 10px;
      height: 10px;
      border-radius: 10px;
    }
  }
  p {
    margin: 0;
  }
  span {
  }
}

.chosen {
  border-color: #73D389 !important;
  color: #73D389 !important;
}
.active:before {
  background-color: #73D389;
}
.passive:before {
  background-color: tomato;
}

.historyOfNotifications {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.historyItem {
  border: 1px solid gray;
  border-radius: 4px;
}


.historyTop {
  display: flex;
  justify-content: flex-end;
}
.historyTop > div {
  border-left: 1px solid gray;
  border-bottom: 1px solid gray;
  border-bottom-left-radius: 4px;
  padding: 4px;
}
.historyCenter {
  padding: 16px;
}
.historyBottom {
  display: flex;
  justify-content: space-between;
}
.historyBottom > div:first-child {
  border-right: 1px solid gray;
  border-top: 1px solid gray;
  border-top-right-radius: 4px;
  padding: 4px;
}
.historyBottom > div:nth-child(2) {
  border-left: 1px solid gray;
  border-top: 1px solid gray;
  border-top-left-radius: 4px;
  padding: 4px;
}