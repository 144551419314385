.container {
  font-family: 'graphic';
  user-select: none;

  * {
    box-sizing: border-box;
    transition: .3s ease all;
  }
}

.table {
  width: 100%;
  border-spacing: 4px;

  th:nth-child(3) {
    .tableHead div:nth-child(2), .tableHead div:nth-child(3) {
      margin-left: 5px;
    }
  }

  td:nth-child(1) {
    div {
      padding: 32px;
      font-weight: 500;
      font-size: 36px;
      height: 205px;
      background-color: #B4D6FF;
      &:not(:first-child) {
        margin-top: 5px;
      }

      &:hover {
        transform: scale(1.03);
      }
    }
  }
  td:nth-child(2) {
    div {
      height: 65px;
      padding: 20px 31px;
      font-weight: 500;
      font-size: 30px;
      &:not(:first-child) {
        margin-top: 5px;
      }
      &:nth-child(1n) {
        background: rgba(108, 175, 253, 0.52);
      }
      &:nth-child(2n) {
        background: rgba(108, 175, 253, 0.26);
      }
      &:hover {
        transform: scale(1.03);
      }
    }
  }
  td:nth-child(3) {
    display: block;
    div {
      & > div {
        margin: 2.5px;
      }
    }
  }
}

.tableHead {
  display: flex;
  text-align: left;

  div {
    padding: 16px 32px;
    width: 100%;
    background: #73D389;
    height: 61px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 32px;
  }
}

.tableF {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(9, 1fr);

  & > div {
    height: 65px;
  }
}

.customer {
  background-color: #CED4DD;
  padding: 14px 10px;
  text-align: center;
  transition: .3s ease all;
  cursor: pointer;

  max-width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  p {
    margin: 20px 0 0 0;
    font-weight: 500;
    font-size: 19px;
  }
  b {
    font-weight: 600;
    font-size: 32px;
  }
  span {
    font-size: 32px;
  }
}

.active {
  transform: scale(1.15);
  z-index: 10;
  color: #fff;
}

.emergingCustomer {
  background-color: #A698FF;
}
.topCustomer {
  background-color: #6CAFFD;
}
.activeCustomer {
  background-color: #73D389;
}
.unsteadyCustomer {
  background-color: #FA9999;
}
.potentialLostCustomer {
  background-color: #DDAEFA;
}
.lostCustomer {
  background-color: #C1F38F;
}
.inactiveCustomer {
  background-color: #7FE0D8;
}

.info {
  margin-top: 3rem;
}

.infoItem {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  cursor: pointer;
  &:hover .infoRound {
    transform: scale(1.2);
  }
}

.infoRound {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-right: 15px;
  flex-shrink: 0;
}

.infoText {
  font-weight: 500;
  font-size: 21px;
}

.error {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    font-weight: 500;
  }
}

.modal {
  text-align: center;

  p {
    font-weight: 500;
    font-size: 22px;
    margin-top: -24px;
  }
  button {
    margin: 0 auto;
  }
}
