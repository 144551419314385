.form {
  font-family: 'graphic';
  width: 350px;
}

.authInput {

}

.authInputs {
  margin-bottom: 20px;
}

.authInput {
  margin-bottom: 18px;
}

.sms {
  font-size: 16px;
  color: #6C6D6C;
  line-height: 22px;
  max-width: 346px;
  margin-bottom: 20px;
}

.authActions {
  display: flex;
  align-items: center;
}

.resetPasswordSuccess {
  text-align: center;
  p {
    font-family: 'graphic';
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 28px;
    margin-top: 0;
  }

  button {
    margin: 0 auto;
  }
}