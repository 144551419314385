.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 9px;
}


.modal {
  margin-top: 200px;
  text-align: center;

  p {
    font-weight: 500;
    font-size: 22px;
    margin-top: -24px;
  }
  button {
    margin: 0 auto;
  }
}

.header {
  width: 100%;
  display: flex;
  padding: 0 0 18px 0;
  border-bottom: 1px solid #ECECEC;
  align-items: center;
  justify-content: flex-start;
}

.header h1 {
  font-family: graphic, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #333333;
  margin: 0 59px 0 0;
}

.header p {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #999999;
}

.table {
  td {
    font-weight: 500;
    font-size: 14px;
  }
}

.period {
  span {
    display: block;
  }
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 101px;
  height: 29px;
  border-radius: 3px;
  background: #FA6262;
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.2px;
  color: #FFFFFF;
}

.statusActive {
  background: #73D389;
}
