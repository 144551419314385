.container {
  position: relative;
  min-height: 100vh;
}

.appOffer {
  position: absolute;
  right: 0;
  top: 0;
  width: 400px;
  height: 400px;
  padding: 46px 57px;
  box-sizing: border-box;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  & > div {
    height: 100%;
    width: 100%;
    position: relative;
  }

   p {
     z-index: 2;
     margin: 0;
     position: relative;

     font-weight: 500;
     font-size: 32px;
     line-height: 40px;
     letter-spacing: -0.02em;
     color: #FFFFFF;
   }
  svg {
    margin-left: 8px;
    margin-right: 4px;
    display: inline;
  }
}

.lang-switcher {
  position: absolute;
  z-index: 20;
  top: 0;
  right: 450px;
}

.rounds {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 180px;
}

.layoutContainer {
  width: 1150px;
  margin: 0 auto;
}

.header {
  position: relative;
  padding-top: 62px;
  margin-bottom: 36px;
}

.footer {
  position: relative;
  margin-top: 20px;
}

.body {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 2;
  position: relative;
  height: 415px;
}

.logo {
  width: 188px;
  height: 58px;
}

.p {
  font-family: 'graphic';
  font-weight: 500;
  font-size: 32px;
  text-align: right;
  max-width: 450px;
  margin: 0 0 0 auto;
}

.info {

}