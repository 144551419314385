.container {
  margin: 58px 22px;
  text-align: center;

  span {
    color: #56BD87;
  }
}

.header {
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  margin-bottom: 26px;
}

.info {
  margin-bottom: 27px;
  display: flex;
  justify-content: center;
  svg {
    margin-top: 2px;
    width: 16px;
    height: 16.5px;
    flex-shrink: 0;
  }
  span {
    margin-left: 8px;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    max-width: 280px;
    display: block;
    text-align: left;
  }
}

.body {
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    margin-bottom: 28px;
  }
  span {
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
  }
}

.btn {
  margin-top: 53px;
  justify-content: center;
  border: none;
  width: 100%;
  max-width: 325px;
  margin-left: auto;
  margin-right: auto;
}