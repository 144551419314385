.modal {
  width: 600px;
  max-width: 1200px;
  min-height: 300px;
  background: #FFFFFF;
  border-radius: 24px;
  display: flex;
  align-items: flex-start;
  position: relative;
}

.leftContent {
  width: 100%;
  max-width: 589px;
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  position: relative;
}

.closeModal{
  z-index: 2;
  cursor: pointer;
  position: absolute;
  top:28px;
  right: 28px;
}

.modalContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px;
  box-sizing: border-box;
}

.modalContainer .p, .rightContent .p {
  font-weight: 500;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 12px;
}

.modalContainer .span{
  font-family: 'graphic';
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: rgba(29, 31, 35, 0.55);
  opacity: 0.6;
}

.textArea {
  height: 130px;
  width: 100%;
  box-sizing: border-box;
  font-family: graphic, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: black;
  padding: 11px 20px;
  border: 1px solid #97D6B9;
  border-radius: 6px;
  outline: none;
  resize: none;
}

.btn {
  text-align: center;
  justify-content: center;
  width: 260px;
  height: 50px;
  margin: 0 auto;
}

.stopBtn {
  text-align: center;
  justify-content: center;
  width: auto;
  height: 50px;
  margin: 12px auto 0;
}

.greenBtn {
  font-family: 'graphic';
  font-size: 16px;
  color: #73D389;
  background: -webkit-linear-gradient(#73D389, #4EB78B);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.history {
  padding: 0;
  list-style: none;
  margin: 0;
  width: 500px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 500px;
  overflow-y: scroll;

  li {
    border: 1px solid gray;
    padding: 8px;
    border-radius: 4px;
    box-sizing: border-box;
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 10px 22px 3fr 86px 1fr;
    gap: 8px;
    position: relative;
    &:first-child {
      border: none;
    }
    p {
      &:last-child {
        text-align: right;
      }
    }

    &:before {
      content: '';
      position: relative;
      width: 10px;
      height: 10px;
      border-radius: 10px;
    }
  }
  p {
    margin: 0;
  }
  span {
  }
}

.active:before {
  background-color: #73D389;
}
.passive:before {
  background-color: tomato;
}