.container {
  width: 100%;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;

  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #E4E7EB;
}

.qr {
  width: 150px;
  height: 150px;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  margin-right: 20px;
  outline: none;
  font-family: 'graphic';
  color: #1D1F23;

  border: 1px solid #6C6D6C;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: transparent;
  padding: 10px 24px;
  cursor: pointer;
  transition: all .3s ease;
  text-decoration: none;
  display: flex;
  align-items: center;

  svg {
    fill: #666666;
    margin-right: 12px;
    transition: all .3s ease;
  }

  &:hover {
    border-color: #73D389;
    svg {
    fill: #4EB78B;
    }
  }
}

.shareLinks {
  display: flex;
  justify-content: space-between;
  button {
    outline: none;
    margin: 4px;
    transition: all .3s ease;
    &:hover {
      opacity: .8;
      img {
        transform: translateY(2px);
      }
    }
    img {
      transition: all .3s ease;
      width: 42px;
      height: 42px;
    }
  }
}