.container {
  outline: none;
  border: 2px solid #E7E7E6;
  box-sizing: border-box;
  border-radius: 8px;
  min-width: 336px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: all .3s ease;

  &:hover {
    border-color: #64C889;
    p {
      color: #64C889;
    }
    svg {
      fill: #64C889;
    }
  }

  svg {
    margin: 32px auto 22px;
    display: block;
    fill: #CCCCCC;
    transition: all .3s ease;
  }
  p {
    color: #1D1F23;
    font-family: 'graphic';
    color: #CCCCCC;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin: 0 auto;
    transition: all .3s ease;
  }
}
