.modal {
  &__paper {
    background-color: #FFF;
    margin: auto;
    border-radius: 30px;

    &--small {
      width: 454px;
    }
    &--medium {
      width: 644px;
    }
    &--large {
      width: 924px;
    }
  }

  &__title {
    margin-right: auto;
    font-weight: 600;
    font-size: 20px;
    color: #121212;

    svg {
      margin-right: 11px;
    }
  }

  &__header {
    display: flex;
    padding: 25px 43px 0;
    box-sizing: border-box;
    justify-content: flex-end;
    z-index: 5;
    &--border-bottom {
      padding-bottom: 20px;
      position: relative;
      &:after {
        content: '';
        width: 89%;
        position: absolute;
        display: block;
        height: 1px;
        background-color: #ccc;
        bottom: 0;
        left: 37px;
      }
    }
  }

  &__body {
    padding: 34px;
    box-sizing: border-box;
    position: relative;
  }

  &__button-close {
    outline: none;
    padding: 0;
    margin: 0;
    border: none;
    width: 32px;
    height: 32px;
    border-radius: 12px;
    background: transparent;
    cursor: pointer;
    z-index: 99;
    transform: translate(28px, -13px);

    img {
      max-width: 100%;
    }
  }
}
