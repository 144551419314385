.container {

}

.question {
}

.questionLine {
  position: relative;
  width: 100%;
  max-width: 441px;
  background-color: rgba(#E7E7E6, .15);
  border: 1px solid rgba(#E7E7E6, .2);
  border-radius: 40px;
  box-sizing: border-box;
  padding: 14px 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.03em;
  color: #1D1F23;
  cursor: pointer;
  & > div {
    transition: .3s ease all;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #E7E7E6;
    border-radius: 40px;
  }
  &:hover {
    border-color: transparent;
  }
  &:hover > div {
    background-color: rgba(#73D389, .3);
  }
  span {
    z-index: 5;
    position: relative;
  }
  span:first-child {
    width: 80%;
    overflow: hidden;
    white-space: break-spaces;
  }
}

.btn {
  margin-top: 2px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: flex-start;

  i {
    margin-left: 9px;
  }
}

.btnOpen {
  color: #73D389;
  margin-bottom: 15px;
}

.btnClose {
  color: #AFAFAB;
  margin-bottom: 15px;
}

.comments {
  margin-top: 18px;
}
