.container {
}

.block {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #E4E7EB;
  &__title {
    font-family: 'graphic';
    font-size: 18px;
    margin-bottom: 16px;
    span {
      color: tomato;
    }
  }
}

.date input{
  width: 90px;
  padding: 0;
  border:none;
  outline: none;
  font-family: graphic, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: rgba(87, 100, 120, 0.73);
}

.modal {
  text-align: center;

  p {
    font-weight: 500;
    font-size: 22px;
    margin-top: -24px;
  }
  button {
    margin: 0 auto;
  }
}