.container {
}

.block {
  font-family: 'graphic';
  padding-bottom: 24px;
  position: relative;
  &__text {
    padding: 0;
    margin: 8px 0 0 0;
    font-size: 16px;
    color: #AFAFAB;
  }
  &__right-text {
    position: absolute;
    top: 43px;
    left: 173px;
    color: #1D1F23;
    font-size: 16px;
  }
  &__btn {
    position: absolute;
    top: 30px;
    left: 233px;
    padding-top: 21px;
    padding-bottom: 21px;
  }
}

.footer {
  margin-top: 16px;
  padding-top: 24px;
  border-top: 1px solid #E4E7EB;
  display: flex;
  button:first-child {
    margin-right: 18px;
  }
}

.modal {
  text-align: center;

  p {
    font-weight: 500;
    font-size: 22px;
    margin-top: -24px;
  }
  button {
    margin: 0 auto;
  }
}

.btn {
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.blocks {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.leftBlock {
  max-width: 380px;
  width: 100%;
}
.rightBlock {
  max-width: 180px;
  width: 100%;
  button {
    margin-left: auto;
  }
  & > div {
    text-align: right;
    margin-bottom: 38px;
    text-transform: capitalize;
  }
  & .green {
    color: #73D389;
  }
  & .red {
    color: #EB5757;
  }
}