.container {
  margin-top: 24px;

  textarea {
    width: 100%;
    height: 130px;
    resize: vertical;
    border: 1px solid #4EB78B;
    outline: none;
    font-size: 16px;
    border-radius: 6px;

    font-family: 'graphic';
    color: #1D1F23;
    padding: 11px 20px;
    box-sizing: border-box;
    margin-bottom: 22px;
  }
}