.container {
  cursor: pointer;
}

.lang-menu {
  font-family: 'graphic';
  width: auto;
  text-align: right;
  font-weight: 500;
  margin-top: 25px;
  position: relative;
}

.lang-menu .selected-lang {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-size: 16px;
  line-height: 32px;
}

.selected-lang i {
  margin-right: 20px;
  content: '';
  display: inline-block;
  width: 32px;
  height: 32px;
  background-size: contain;
  background-repeat: no-repeat;
}

.lang-menu ul {
  margin: 0;
  padding: 0;
  display: none;
  background-color: #fff;
  border: 1px solid #f8f8f8;
  position: absolute;
  top: 45px;
  left: 40%;
  transform: translateX(-50%);
  width: 125px;
  border-radius: 5px;
  box-shadow: 0 1px 10px rgba(0,0,0,0.2);
}


.lang-menu ul li {
  list-style: none;
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.lang-menu ul li a {
  text-decoration: none;
  width: 125px;
  padding: 5px 10px;
  display: block;
  color: #1a1a1a;
}

.active {
  color: #4EB78B !important;
}

.lang-menu ul li:hover {
  background-color: #f2f2f2;
}

.lang-menu ul li a i {
  content: '';
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align: middle;
  margin-right: 10px;
  background-size: contain;
  background-repeat: no-repeat;
}

.lang-menu--active ul {
  display: block;
}