.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 0.5em;
}


.table thead tr:first-child {
    background: #F8F8F8;
}

.table tr:first-child th:first-child {
    border-radius: 6px 0 0 6px;
}

.table tr:first-child th:last-child {
    border-radius: 0 6px 6px 0;
}

.table tr {
    font-family: sf, serif;
    color: #333333;
    font-style: normal;
    height: 50px;
}
.table tbody tr:hover{
    background: #F6F6F5;
}

.table tbody tr td {
    cursor: pointer;
    border-top: 1px solid #E7E7E6;
    border-bottom: 1px solid #E7E7E6;
}

.table tbody tr td:first-child {
    padding: 10px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    border-radius: 4px 0 0 4px;
    border-left: 1px solid #E7E7E6;
}

.table tbody tr td:first-child p{
    margin: 0;
    padding: 0;
    font-family: sf, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #ADADAD;
}

.table tbody tr td:last-child {
    border-radius: 0 4px 4px 0;
    border-right: 1px solid #E7E7E6;
    padding-right: 8px;
}

.table tr th {
    text-align: left;
    padding-left: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
}

.table tbody tr td {
    padding-left: 20px;
    text-align: left;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
}


.pagination {
    margin-top: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination i {
    background: #F5F5F5;
    padding: 12px 15px;
    border-radius: 6px;
    color: black;
    cursor: pointer;
}

.paginationNumbers {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 18px;
}

.paginationNumbers p {
    cursor: pointer;
    background: #F5F5F5;
    padding: 12px 16px;
    border-radius: 6px;
    color: black;
    margin: 0 3px !important;
    font-size: 16px;
    line-height: 19px;
}



.status{
    font-family:sf, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    border-radius: 3px;
}
