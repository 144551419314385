.pagination {
    margin-top: 18px;
    display: flex;
}

.pagination button {
    background-color: #F5F5F5;
    padding: 10px 15px;
    border-radius: 6px;
    color: black;
    cursor: pointer;
    margin: 0 3px !important;
    font-size: 16px;
    line-height: 19px;
    border: none;
    display: flex;
    align-items: center;
    height: 38px;
    min-width: 38px;
    transition: .3s ease all;
    outline: none;
}

.pagination button:hover {
    background-color: #E7E7E6;
}
.pagination .active {
    background-color: #E7E7E6;
    cursor: default;
}

.loading {
    cursor: not-allowed !important;
    opacity: .5;
}

.paginationNumbers {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 18px;
}
