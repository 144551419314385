.modal {
    width: 425px;
    background: #FFFFFF;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    position: relative;
}

.closeModal {
    z-index: 2;
    cursor: pointer;
    position: absolute;
    top:28px;
    right: 28px;
}

.container{
    width: 100%;
}