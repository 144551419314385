.container {
  position: relative;
}

.hide {
  position: absolute;
  z-index: -999;
  opacity: 0;
}

.tester {
  border-bottom: 1px solid #E7E7E6;
  padding-top: 24px;
  padding-bottom: 24px;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border: none;
  }
  & > p {
    font-weight: 500;
    font-size: 18px;
    color: #1D1F23;
    line-height: 21px;
    margin: 0 0 16px 0;
  }
}

.testerInfo {
  padding-left: 16px;
  border-left: 1px solid #E7E7E6;
  p {
    font-size: 16px;
    line-height: 19px;
  }
  span {
    color: #AFAFAB;
    margin-right: 5px;
  }
  b {
    font-weight: 400;
  }
}

.btn {
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  stroke: #1D1F23;
  svg {
    transition: .3s ease all;
  }
  &:hover {
    stroke: #73D389;
  }
}

.chosenTester {
  background-color: #fff;
  margin-top: -251px;
  padding-top: 15px;
}

.chosenTesterTop {
  display: flex;
  align-items: flex-start;
  button {
    margin-right: 20px;
    transform: translateY(-3px);
  }
}

.checked {
  margin-bottom: 24px;
  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #333333;
    margin: 0 0 18px 0;
  }
  div {
    display: flex;
    align-items: center;
    span {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 2px;
      display: inline-block;
      font-weight: 400;
      color: #1D1F23;
    }
    i {
      margin-right: 13px;
    }
  }
}
