.container {
    display: flex;
    height: 100%;
    align-items: flex-start;
    background: #FFFFFF;
    border-radius: 24px;
    padding: 28px;
}

.analytics {
    width: 100%;
}