.container {
  font-family: 'graphic';
  user-select: none;

  * {
    box-sizing: border-box;
    transition: .3s ease all;
  }
}

.top{
  display: flex;
  align-items: center;
  justify-content: space-between;

  input {
    min-width: 408px;
  }
}

.info {
  margin: 25px 0 26px;
  table {
    text-align: left;
    width: 100%;
    border-spacing: 5px;
    font-size: 18px;
    font-family: 'graphic';
    table-layout: fixed;

    tr {
    }

    th {
      font-weight: 400;
      padding-right: 95px;
      padding-bottom: 20px;
    }
    td {
      padding-bottom: 10px;
    }

    b {
      font-weight: 500;
      text-overflow: ellipsis;
      display: inline-block;
      overflow: hidden;
      padding-right: 10px;
      vertical-align: middle;
    }
  }

  h2 {
    font-weight: 500;
    font-size: 22px;
  }
}
.infoTop {
  margin-top: 30px;
  font-size: 36px;
  margin-bottom: 20px;
  max-width: 700px;
  b {
    color: #5769EB;
  }
  span {
    opacity: .5;
    color: #5769EB;
  }
}

.flex {
  display: flex;
  align-items: center;
  & > div {
    flex-shrink: 1;
    margin-right: 15px;
  }
  & > b {
    flex-shrink: 0;
    display: inline-block;
    min-width: 150px;
  }
}

.modal {
  width: 589px;
  min-height: 590px;
  background: #FFFFFF;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  position: relative;
}

.closeModal{
  z-index: 2;
  cursor: pointer;
  position: absolute;
  top:28px;
  right: 28px;
}

.header {
  margin-bottom: 20px;
}

.header {
  p {
    text-align: center;
    align-self: center;
    margin: 0;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    line-height: 29px;
    font-size: 24px;
    color: #333333;
  }
}

.modalContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px;
  box-sizing: border-box;
}

.modalContainer .p{
  font-weight: 500;
  font-size: 18px;
}

.modalContainer .span{
  font-family: 'graphic';
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: rgba(29, 31, 35, 0.55);
  opacity: 0.6;
}

.textArea {
  height: 130px;
  width: 100%;
  box-sizing: border-box;
  font-family: graphic, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: black;
  padding: 11px 20px;
  border: 1px solid #97D6B9;
  border-radius: 6px;
  outline: none;
  resize: none;
}

.btn {
  text-align: center;
  justify-content: center;
  width: 260px;
  height: 50px;
}

.bonuses {
  padding-right: 17px;
  padding-left: 17px;

  button {
    margin: 50px auto 0;
  }
}

.bonusesHeader {
  color: #1D1F23;
  font-weight: 300;
  font-size: 18px;
  display: flex;
  margin-bottom: 24px;

  svg {
    flex-shrink: 0;
    margin-top: 4px;
  }
  p {
    padding: 0;
    margin: 0 0 0 10px;
  }
}

.subTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #333333;
  margin: 0 0 14px 0;
}

.inputBonus {
  color: #73D389;
  margin-top: 20px;
  background-color: #73D389;
  background-image: linear-gradient(to bottom, #73D389, #4EB78B);
  -webkit-background-clip: text;

  font-weight: 500;
  font-size: 48px;
  outline: none;
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  border: none;
}

.bonusInput {
  width: 491px;
  height: 75px;
  border: 1px solid #E7E7E6;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  color: #333333;
}

.green {
  border-color: #73D389;
  margin-bottom: 10px;
}

.cash {
  span {
    color: #333333;
    opacity: 0.6;
    font-size: 16px;
  }
  b {
    font-weight: 500;
    font-size: 16px;
    color: #333333;
  }
}

.bonusesResult {
  & > div {
    display: flex;
    padding: 12px 39px;
    box-sizing: border-box;
    & > span {
      font-size: 22px;
      line-height: 26px;
      color: #1D1F23;
      display: inline-block;
      &:nth-child(2) {
        max-width: 298px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &:first-child {
        width: 50%;
        margin-right: 39px;
      }
    }
    b {
      font-size: 22px;
      line-height: 26px;
      color: #1D1F23;
      margin-right: 39px;
      width: 50%;
    }
  }
}

.bonusesResultItem {
  margin-top: 20px;
  padding: 26px 39px !important;
  left: 484px;
  top: 362px;
  background: rgba(#4EB78B, .1);
  border-radius: 12px;
}
