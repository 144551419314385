.container {
  position: relative;
  user-select: none;
  overflow: hidden;

  svg {
    width: 14px;
    height: 14px;

    opacity: 0;
    transition: all .1s ease;
  }

  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    span {
      font-family: 'graphic';
      font-size: 16px;
    }
  }
}

.input {
  display: none;
}

.input:checked + .customCheckbox {
  border: none;
  background-color: #4EB78B;
  background: linear-gradient(180deg, #73D389 0%, #4EB78B 100%);
}

.input:checked + .customCheckbox svg {
  opacity: 1;
  fill: #fff;
}
.input:checked + .customCheckbox .square {
  opacity: 1;
}

.customCheckbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border: 1px solid rgba(#1D1F23, .2);
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 15px;
  flex-shrink: 0;
  svg {
    fill: #4EB78B;
  }
}

.square {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  opacity: 0;


  svg {
    fill: #fff;
  }
}

.round {
  border-radius: 100%;
}
