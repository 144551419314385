.form {
  width: 350px;
}

.authActions {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}

.authInputs {
  position: relative;
}

.authInput {
  margin-bottom: 24px;
}

.forgetPasswordBtn {
  font-size: 16px;
  background: -webkit-linear-gradient(#73D389, #4EB78B);
  -webkit-background-clip: text;
  font-weight: 400;
  -webkit-text-fill-color: transparent;
  position: absolute;
  left: 100px;
  line-height: 20px;
  outline: none;
  cursor: pointer;
}

.checkbox {
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: -15px;
}

.takeConditionBtn {
  margin-top: 30px;
  a {
    text-decoration: none;
    font-family: 'graphic';
    font-size: 18px;
    color: #1D1F23;
    span {
      color: #4EB78B;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}