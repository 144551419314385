.container {

}

.title {
  font-weight: 500;
  font-size: 48px;
  color: #1D1F23;
  margin-bottom: 22px;
}

.b {
  font-family: 'graphic';
  font-weight: 500;
  color: #1D1F23;
  letter-spacing: -0.02em;
  font-size: 24px;
}

.p {
  font-family: 'graphic';
  color: rgba(29, 31, 35, 0.65);
  font-size: 18px;
  margin: 12px 0 16px;
}

.img {
 max-width: 563px;
  margin-top: 115px;
}
