.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px 0;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
}

.notRegistered {
    text-align: center;
    margin-bottom: 14px;
    margin-top: -30px;
    div {
        max-width: 490px;
        margin: 0 auto;
        display: flex;
        align-items: flex-start;
    }
    img {
        width: 18px;
        height: 18px;
        flex-shrink: 0;
        margin-right: 10px;
    }
    p {
        margin-top: 30px;
        margin-bottom: 20px;
        font-size: 24px;
        font-weight: 500;
        color: #4EB78B;
    }
    span {
        text-align: left;
        font-weight: 300;
        font-size: 18px;
        color: #1D1F23;
    }
}

.header p {
    align-self: center;
    margin: 0;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5em;
    line-height: 29px;
}

.header span {
    margin: 28px 0 0 0;
    align-self: center;
    background: linear-gradient(0deg, rgba(115, 211, 137, 0.04) 0%, rgba(78, 183, 139, 0.04) 100%);
    border-radius: 8px;
    color: #59BF8A;
    padding: 19px 100px;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.375em;
    line-height: 26px;
}

.points, .amount, .getPoints {
    display: flex;
    flex-direction: column;
}

.payment {
    user-select: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 49px;

    img {
        transition: .3s ease all;
        border-radius: 5px;
        cursor: pointer;
        max-width: 100%;
        position: relative;
        height: 100%;
    }

    & > div {
        position: relative;
        height: 46px;
        cursor: pointer;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 10;
            border: 3px solid transparent;
            border-radius: 3px;
        }
    }
    .active:before {
        border-color: #73D389;
        background: rgba(#fff, .3);
    }
    .active {

    }
}

.points p {
    margin-left: 49px;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.125em;
    line-height: 21px;
    color: #333333;
    align-self: flex-start;
}

.points span {
    align-self: center;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 3em;
    line-height: 57px;
    color: #59BF8A;
}


.amount p {
    margin-left: 49px;
    align-self: flex-start;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.125em;
    line-height: 21px;
    color: #333333;
}

.amount input {
    align-self: center;
    padding: 21px 0 21px 32px;
    border: 1px solid #E7E7E6;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    color: #ADADAD;
    outline: none;
    -moz-appearance: textfield;
    &::placeholder {
        font-size: 16px;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}


.getPoints p {
    margin-left: 49px;
    align-self: flex-start;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.125em;
    line-height: 21px;

    color: #333333;
}

.button {
    font-family: 'graphic';
    font-weight: 500;
    margin-left: 49px;
    align-self: flex-start;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 18px;
    padding: 0;
    outline: none;
    margin-top: 0;

    color: #4EB78B;
}

.inputContainer {
    width: 455px;
    align-self: center;
}

.input {
    width: 455px;
    align-self: center;
    padding: 21px 0 21px 32px;
    border: 1px solid #5CC28B;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    border-radius: 8px;
    color: #333333;
    outline: none;
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}


.bonusesAmount {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bonusesAmount p {
    font-family: sf, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1em;
    line-height: 19px;
    color: #858585;
}

.bonusesAmount p span {
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 19px;
    color: #333333;
}

.bonusesAmount button {
    cursor: pointer;
    margin-right: 49px;
    background: inherit;
    border: none;
    outline: none;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 19px;
    color: #4EB78B;
}

.sendButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.sendButton button {
    cursor: pointer;
    margin-top: 24px;
    background: linear-gradient(180deg, #73D389 0%, #4EB78B 100%);
    border-radius: 8px;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5em;
    line-height: 20px;
    padding: 22px 66px;
    color: #FFFFFF;
    outline: none;
    border: none;
}

.getPoints2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;

    .getPoints2Counts {
        font-weight: 500;
        font-size: 28px;
        line-height: 33px;
        color: #333333;
        -webkit-text-fill-color: #333333;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #E7E7E6;
        margin-top: 0;
        margin-left: 0;
    }

    button {
        background: #705AFB;
        border-radius: 6px;
        font-size: 18px;
        line-height: 16px;
        text-align: center;
        color: #FFFFFF;
        padding: 16px 32px;
    }
}

