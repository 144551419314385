.container {
    width: 100%;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    margin-top: 45px;
    margin-bottom:40px;
    transition: .3s ease all;
}

.container-pending {
    opacity: .3;
}

.arrows{
    color:#666666;
    font-size: 16px;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    transform: translate(0, -18px);
    flex-shrink: 0;
    flex-grow: 0;
}

.arrows > svg {
    fill: #666666;
    transition: all .3s ease;
}

.arrows:hover > svg {
    fill: #4EB78B;
}

.arrows-left{
    left: 21px;
}

.arrows-right{
    right: 21px;
}

.arrows:hover{
    color:#4EB78B;
}

.item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 150px;
}

.item img{
    width: 75px;
    height: 75px;
    display: block;
    border: 2px solid transparent;
    overflow: hidden;
    border-radius: 50px;
}

.item p{
    margin: 16px 0 8px 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    /* identical to box height */

    text-align: center;
    letter-spacing: -0.132544px;

    color: #FFFFFF;
}

.item span{
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: -0.0994082px;

    color: #FFFFFF;

    opacity: 0.8;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    display: block;
    max-height: 30px;
}

.dots{
    cursor: pointer;
    position: absolute;
    display: flex;
    justify-content: space-evenly;
    bottom: -25px;
}

.modal {
    text-align: center;
}

.modal p {
    font-weight: 500;
    font-size: 22px;
    margin-top: -24px;
}

.modal button {
    margin: 0 auto;
}