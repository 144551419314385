.container {
  position: relative;
  padding: 9px 0 0 50px;
}

.dataWrapper {
  display: flex;
  align-items: flex-start;
}

.itemTitle, .itemValue {
  display: flex;
  flex-direction: column;
}

.itemTitle p {
  white-space: nowrap;
  margin: 0;
  font-family: sf, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.125em;
  line-height: 21px;
  color: #1D1F23;
}

.itemValue span {
  font-family: sf, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125em;
  line-height: 21px;
  color: #1D1F23;
  background: #F6F6F5;
  border-radius: 6px;
  padding: 19px 0 19px 34px;
  width: 13.9vw;

  &:nth-child(odd) {
    margin: 10px 0;
  }
}

.topData {
  margin-bottom: 90px;
}