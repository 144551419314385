.container {
  color: #1D1F23;

  .description {
    width: 100%;
    text-align: left;
    border-spacing: 5px;
    table-layout: fixed;

    thead tr th {
      font-weight: 400;
      padding-bottom: 6px;
    }
    tbody tr td {
      padding-left: 20px;
    }
  }
}

.text {
  font-size: 17px;
  opacity: 0.6;
}

.form {
  margin-top: 30px;
}

.formTable {
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #E4E7EB;
  padding-bottom: 24px;
  margin-bottom: 24px;

  th, td {
    width: 33.3%;

    span {
      color: #AFAFAB;
      margin-top: 8px;
      font-size: 16px;
      display: block;
      padding-left: 2px;
    }
  }

  thead tr th {
    font-weight: 400;
    padding-bottom: 15px;
  }

  tbody tr td {
    padding: 8px 0;
    & > div {
      width: 196px;
    }
  }
}

.infoTable {
  text-align: left;
  width: 100%;
  border-spacing: 5px;
  font-size: 18px;
  font-family: 'graphic';
  table-layout: fixed;

  th {
    vertical-align: top;
    font-weight: 400;
    padding-right: 95px;
    padding-bottom: 20px;
  }
  td {
    font-family: 'sf';
    padding-bottom: 5px;
    font-size: 18px;
    color: #000;
  }

  b {
    font-weight: 500;
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
    padding-right: 10px;
    vertical-align: middle;
  }

  img {
    width: 175px;
    height: 175px;
  }
}

.flex {
  display: flex;
  align-items: center;
  & > div {
    flex-shrink: 0;
    margin-right: 15px;
  }
}

.modal {
  text-align: center;

  p {
    font-weight: 500;
    font-size: 22px;
    margin-top: -24px;
  }
  button {
    margin: 0 auto;
  }
}
