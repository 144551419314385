.container {
  height: 100%;
  background: #FFFFFF;
  border-radius: 24px;
  padding: 28px;
}

.reviews {

}

.reviewsHead {
  display: flex;
  align-items: center;
}

.reviewsBody {
  margin-top: 24px;
}