.container {
  font-family: 'graphic';
  height: 100%;
  background: #FFFFFF;
  border-radius: 24px;
  padding: 28px;
}

.header {
  display: flex;
  justify-content: space-between;

  h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #73D389;
    margin-bottom: 29px;
    margin-top: 0;
  }

  span {
    display: block;
    white-space: nowrap;
  }

  &__left {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        font-size: 14px;
        line-height: 28px;
        letter-spacing: -0.02em;
        color: #000000;
        font-weight: 500;
      }
    }
  }
  &__right {
    text-align: right;

    p {
      font-size: 14px;
      line-height: 28px;
      letter-spacing: -0.02em;
      color: #000000;
      font-weight: 500;
    }
  }
}

.body {
  position: relative;
  padding-top: 60px;
  display: flex;
  justify-content: center;

  span {
    color: #73D389;
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
}

.img {
  box-sizing: border-box;
  //max-width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  iframe {
    border: none;
    width: 100%;
    height: 600px;
  }

  img {
    margin: 0 auto;
    max-width: 100%;
  }
}

.modal {
  text-align: center;

  p {
    font-weight: 500;
    font-size: 22px;
    margin-top: -24px;
  }
  button {
    margin: 0 auto;
  }
}