.container{
    display: flex;
    align-items: center;
    justify-content: center;
}

.container img{
    margin-right: 18px;
}

.container input{
    width: 90px;
    padding: 0;
    border:none;
    outline: none;
    font-family: graphic, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: rgba(87, 100, 120, 0.73);
}


.container input::placeholder {
    text-align: center;
    color: rgba(87, 100, 120, 0.73);
}

.container input::-webkit-input-placeholder {
    text-align: center;
    color: rgba(87, 100, 120, 0.73);
}

.container input:-moz-placeholder {
    text-align: center;
    color: rgba(87, 100, 120, 0.73);
}

.container span{
    margin: 0 7px 0 0px;
    font-family: graphic, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: rgba(87, 100, 120, 0.73);
    transform: translateY(1px);
}
/* ------------- */
.singleContainer{
    display: flex;
    align-items: center;
}

.singleContainer img{
    cursor: pointer;
}

.singleContainer input {
    background-color: transparent;
    width: 96px;
    border:none;
    outline: none;
    font-family: graphic, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: rgba(87, 100, 120, 0.73);
    padding: 2px 20px;
}

.singleContainer span{
    margin: 0 5px 0 2px;
    font-family: graphic, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: rgba(87, 100, 120, 0.73);
}

.empty {

}

.emptyLeft {

}

.emptyRight {

}
