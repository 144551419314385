.container {
  display: flex;
  height: 100%;
  align-items: flex-start;
  background: #FFFFFF;
  border-radius: 24px;
  padding: 28px;
}

.layoutContainer {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: flex-start;
  background: #FFFFFF;
  border-radius: 24px;

  & > div:nth-child(2) {
    width: 100%;
  }
}


.content {
  width: 100%;
}