.container {
}

.clientsTopContainer {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}
.clientsTopContainer > div {
  max-width: 500px;
}

.block {
  font-family: 'graphic';
  padding-bottom: 24px;
  position: relative;
  &__text {
    padding: 0;
    margin: 8px 0 0 0;
    font-size: 16px;
    color: #AFAFAB;
  }
  &__right-text {
    position: absolute;
    top: 43px;
    left: 173px;
    color: #1D1F23;
    font-size: 16px;
  }
  &__btn {
    position: absolute;
    top: 30px;
    left: 233px;
    padding-top: 21px;
    padding-bottom: 21px;
  }
}

.footer {
  margin-top: 16px;
  padding-top: 24px;
  border-top: 1px solid #E4E7EB;
  display: flex;
  button:first-child {
    margin-right: 18px;
  }
}

.modal {
  text-align: center;

  p {
    font-weight: 500;
    font-size: 22px;
    margin-top: -24px;
  }
  button {
    margin: 0 auto;
  }
}

.btn {
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.clients {
  margin-top: 32px;
}

.clients h2 {
  font-weight: 500;
}

.clientModal {
  display: flex;
  justify-content: center;
  margin-top: -16px;
  padding: 40px 90px 20px 30px;

  img {
    display: block;
    width: 88px;
    height: 88px;
    border-radius: 88px;
  }

  span {
    color: #6C6D6C;
    font-size: 18px;
  }

  p {
    color: #1D1F23;
    font-weight: 500;
    font-size: 24px;
    margin: 4px 0 13px;
  }

  button {
    margin-top: 16px;
    max-width: 200px;
    width: 167px;
    text-align: center;
    justify-content: center;
  }

  div {
    &:nth-child(1) {}
    &:nth-child(2) {
      margin-left: 26px;
    }
  }
}
