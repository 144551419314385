.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 9px;
}

.container h1 {
  margin: 0;
  font-family: graphic, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #333333;
  width: 100%;
  padding: 0 0 18px 0;
  border-bottom: 1px solid #ECECEC;
}

.form {
  width: 100%;
}

.fieldset {
  border: none;
}

.formContent {
  margin-top: 28px;
  width: 100%;
  display: flex;
  & > div:nth-child(1) {
    width: 55%;
  }
  & > div:nth-child(2) {
    width: 45%;
  }
}

.formFooter {
  border-top: 1px solid #ECECEC;
  margin-top: 24px;
  padding: 24px 0;
  display: flex;
  align-items: center;
  & > button {
    margin-right: 20px;
  }
}

.block {
  font-family: 'graphic';
  padding-bottom: 24px;
  position: relative;
  & > b {
    display: inline-block;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin: 0 15px 10px 0;
    color: #1D1F23;
    span {
      color: red;
    }
  }
  &__text {
    padding: 0;
    margin: 8px 0 0 0;
    font-size: 16px;
    color: #AFAFAB;
  }
  &__right-text {
    position: absolute;
    top: 43px;
    left: 173px;
    color: #1D1F23;
    font-size: 16px;
  }
  &__btn {
    position: absolute;
    top: 30px;
    left: 233px;
    padding-top: 21px;
    padding-bottom: 21px;
  }
  &_date {
    & > div {
      width: 100%;
      justify-content: flex-start;
    }
  }
}

