.navigation {
  display: flex;
  flex-direction: column;
  margin-right: 27px;
}

.navigation {
  a {
    font-family: 'graphic';
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 15px 26px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #1D1F23;
    opacity: 0.6;
    border: 2px solid #E7E7E6;
    box-sizing: border-box;
    border-radius: 6px;
    width: 262px;
    margin: 8px 0;
    transition: all .3s ease;
    &:first-child {
      margin-top: 0;
    }
    &.activeTab {
      border-color: #64C889;
      opacity: 1;
      svg {
        fill: #4EB78B;
      }
    }
    &:hover {
      border-color: #64C889;
      opacity: 1;
      svg {
        fill: #4EB78B;
      }
    }
  }
  svg {
    margin-right: 21px;
    fill: #AFAFAB;
    transition: fill .3s ease;
    width: 20px;
    height: 20px;
  }
}

.content {
  margin-top: 24px;
  width: 100%;
}

.modal {
  width: 589px;
  min-height: 590px;
  background: #FFFFFF;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  position: relative;
}

.closeModal{
  z-index: 2;
  cursor: pointer;
  position: absolute;
  top:28px;
  right: 28px;
}

.modalContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px;
  box-sizing: border-box;
}

.modalContainer .p{
  font-weight: 500;
  font-size: 18px;
}

.modalContainer .span{
  font-family: 'graphic';
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: rgba(29, 31, 35, 0.55);
  opacity: 0.6;
}

.textArea {
  height: 130px;
  width: 100%;
  box-sizing: border-box;
  font-family: graphic, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: black;
  padding: 11px 20px;
  border: 1px solid #97D6B9;
  border-radius: 6px;
  outline: none;
  resize: none;
}

.btn {
  text-align: center;
  justify-content: center;
  width: 260px;
  height: 50px;
}

.header {
  margin-bottom: 21px;
}
