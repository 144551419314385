.button-filled {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 22px 38px;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
  box-sizing: border-box;
  max-width: fit-content;

  &--rounded {
    border-radius: 96px;
  }

  &:hover {
    text-decoration: none;
  }

  &--orange {
    background: #FFAE1A;
    border-color: #FFCF5C;
  }

  &--green {
    background: linear-gradient(180deg, #73D389 0%, #4EB78B 100%);
    border-color: #73D389;
  }

  &--red {
    background: #FA6262;
    border-color: #FA6262;
  }

  &--purple {
    background: #705AFB;
    border-color: #705AFB;
  }

  &--disabled {
    border-color: rgba(102, 102, 102, 0.08);
    opacity: .6;
    cursor: not-allowed;

    .button-filled__text {
      color: #B3B3B3;
    }
  }

  .button-filled {
    &__text {
      color: #FFF;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;

      &--hidden {
        visibility: hidden;
      }
    }

    &__spinner {
      position: absolute;
      width: 22px;
      height: 22px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
