.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: flex-start;
    background: #FFFFFF;
    border-radius: 24px;
    padding: 26px 69px;
    position: relative;
}

.backButton {
    cursor: pointer;
    position: absolute;
    top: 28px;
    left: 28px;
}

.container h2 {
    margin: 0;
    font-family: graphic, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #1D1F23;
}

.container textarea {
    font-family: graphic, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: black;
    margin: 19px 0 24px 0;
    width: 511px;
    height: 130px;
    padding: 11px 20px;
    border: 1px solid #97D6B9;
    border-radius: 6px;
    outline: none;
    resize: none;
}

.photosName {
    display: flex;
    align-items: center;
    justify-content: center;
}

.photosName span {
    color:#73D489;
    border-bottom: 1px dashed #73D489;
    font-family: graphic, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
}

.photosName img{
    cursor: pointer;
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 24px 0;
}

.buttons button {
    cursor: pointer;
    padding: 16px 47px;
    outline: none;
    border: none;
    background: linear-gradient(180deg, #73D389 0%, #4EB78B 100%);
    border-radius: 6px;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    color: #FFFFFF;
}

.buttons button:hover {
    background: linear-gradient(180deg, #73D389 100%, #4EB78B 100%);
}

.uploadFiles {
    cursor: pointer;
    margin: 0 0 0 32px;
}

.uploadFiles .form-group {
    padding: 1em;
    margin: 1em
}

.uploadFiles input[type=file] {
    display: none;
}

