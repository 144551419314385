.pagination {
    margin-top: 18px;
    display: flex;
    list-style: none;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    align-items: center;
}

.pagination button {
    background-color: #F5F5F5;
    padding: 10px 15px;
    border-radius: 6px;
    color: black;
    cursor: pointer;
    margin: 0 3px !important;
    font-size: 16px;
    line-height: 19px;
    border: none;
    display: flex;
    align-items: center;
    height: 38px;
    min-width: 38px;
    transition: .3s ease all;
    outline: none;
}

.pagination a {
    background-color: #F5F5F5;
    padding: 10px 15px;
    border-radius: 6px;
    color: black;
    cursor: pointer;
    margin: 0 3px !important;
    font-size: 16px;
    line-height: 19px;
    border: none;
    display: flex;
    align-items: center;
    transition: .3s ease all;
    outline: none;
    height: 17px;
}

.loadingContainer {
    position: relative;
}

.loadingContainer:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}

.selected a {
    background-color: #E7E7E6;
    cursor: default;
}

.pagination button:hover {
    background-color: #E7E7E6;
}
.pagination .active {
    background-color: #E7E7E6;
    cursor: default;
}

.loading {
    cursor: not-allowed !important;
    opacity: .5;
}

.paginationNumbers {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 18px;
}
