.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 130px 65px 0 65px;
}

.container p {
    font-family: 'sf', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #333333;
}

.container .inner {
    max-width: 325px;
    margin: 0 auto;
    text-align: center;
}

.container .secondInner {
    max-width: 425px;
    width: 100%;
}

.container input {
    border: 1px solid #68CB8A;
    outline: none;
    padding: 23px 20px 23px 32px;
    border-radius: 8px;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.3em;
    line-height: 29px;
}

.container .btn {
    cursor: pointer;
    margin-top: 24px;
    background: linear-gradient(180deg, #73D389 0%, #4EB78B 100%);
    border-radius: 8px;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 20px;
    padding: 22px 66px;
    color: #FFFFFF;
    outline: none;
    border: none;
    &:disabled {
        cursor: default;
        opacity: .4;
    }
}

.container .switcher {
    cursor: pointer;
    font-size: 18px;
    background-color: transparent;
    border: none;
    color: #4EB78B;
    text-align: left;
    outline: none;
    margin-top: 6px;
    margin-right: auto;
    &:hover {
    opacity: .8;
}
}

.span {
    display: block;
    margin-top: 5px;
    color: red;
    width: 100%;
}
