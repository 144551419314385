.container {
  display: flex;
  height: 100%;
  align-items: flex-start;
  background: #FFFFFF;
  border-radius: 24px;
}

.navigation {
  display: flex;
  flex-direction: column;
  margin-right: 57px;
}

.navigation {
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 15px 26px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #1D1F23;
    opacity: 0.6;
    border: 2px solid #E7E7E6;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: graphic, serif;
    width: 262px;
    margin: 8px 0;
    transition: all .3s ease;
    &:first-child {
      margin-top: 0;
    }
    &.activeTab {
      border-color: #64C889;
      opacity: 1;
      svg {
        fill: #4EB78B;
      }
      .automailingSvg {
        stroke: #64C889 !important;
      }

    }
    &.activeTab.stroke path {
      stroke: #4EB78B !important;
    }
    &.stroke {
      &:hover path {
        stroke: #4EB78B !important;
      }
    }
    &:hover {
      border-color: #64C889;
      opacity: 1;
      svg {
        fill: #4EB78B;
      }
      .automailingSvg {
        stroke: #64C889 !important;
      }
    }
  }
  svg {
    margin-right: 21px;
    fill: #AFAFAB;
    transition: fill .3s ease;
    width: 20px;
    height: 20px;
  }
}

.content {
  width: 100%;
}

.automailingSvg {
  fill: #fff !important;
  stroke: #AFAFAB;
}
