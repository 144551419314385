.container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.container h3{
    margin: 0;
    font-family: graphic, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #1D1F23;
}


.pagination {
    margin-top: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination i {
    background: #F5F5F5;
    padding: 12px 15px;
    border-radius: 6px;
    color: black;
    cursor: pointer;
}

.paginationNumbers {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 18px;
}

.paginationNumbers p {
    cursor: pointer;
    background: #F5F5F5;
    padding: 12px 16px;
    border-radius: 6px;
    color: black;
    margin: 0 3px !important;
    font-size: 16px;
    line-height: 19px;
}

