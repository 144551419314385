.container {
  position: relative;
  cursor: pointer;
}

.text {
  max-width: 280px !important;
  line-height: 20px;
  padding: 24px !important;
  font-size: 16px !important;
}
