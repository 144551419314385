.container{
    display:flex;
    align-items: center;
    justify-content:center;
    margin-right: 30px;
}

.circle{
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 10px;
}
