.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px 0;
  text-align: center;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  justify-content: center;
}

.header p {
  align-self: center;
  margin: 0;
  font-family: sf, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5em;
  line-height: 29px;
}

.header span {
  margin: 28px 0 0 0;
  align-self: center;
  background: linear-gradient(0deg, rgba(115, 211, 137, 0.04) 0%, rgba(78, 183, 139, 0.04) 100%);
  border-radius: 8px;
  color: #59BF8A;
  padding: 19px 100px;
  font-family: sf, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.375em;
  line-height: 26px;
}

.award {
  display: flex;
  justify-content: center;

  div {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.awardOut {
  width: 250px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.gift {
  flex-basis: 85px;
  width: 85px;
  height: 85px;
  margin: 0 auto 0;
  flex-shrink: 0;
  flex-grow: 1;
  transform: translateY(10px);
}

.numbers {
  margin-bottom: 0;
  margin-top: 0;
  text-align: center;
  font-family: 'gilroy';
  font-weight: 500;
  font-size: 34px;
  color: #576478;
  transform: translateY(32px);
}

.indicator {
  font-family: 'graphic';
  position: absolute;
  right: 10px;
  bottom: 48px;
  width: 60px;
  height: 60px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    transform: translateY(-3px);
  }
}

.sendButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.sendButton button {
  cursor: pointer;
  margin-top: 24px;
  background: linear-gradient(180deg, #73D389 0%, #4EB78B 100%);
  border-radius: 8px;
  font-family: sf, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5em;
  line-height: 20px;
  padding: 22px 66px;
  color: #FFFFFF;
  outline: none;
  border: none;
}