.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 300px;
}

.container h3 {
    align-self: flex-start;
    margin: 0;
    font-family: graphic, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #1D1F23;
}

.subTitle {
    margin-bottom: 20px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.subTitle p {
    font-family: graphic, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5vw;
    line-height: 44px;
    color: #576478;
    margin: 0;
}

.subTitle span {
    font-family: graphic, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.72vw;
    line-height: 17px;
    color: rgba(87, 100, 120, 0.73);
}


.legends {
    display: flex;
    justify-content: center;
    align-items: center;
}