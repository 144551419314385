.container {
  position: relative;
  margin: 0 6px 0 0;
  width: 139px;
  height: 42px;

  transition: .3s ease all;

  .input {
    display: flex;
    align-items: center;
    border: 1px solid #DBDBDB;
    border-radius: 6px;
    height: 34px;
    padding: 2px 7px;
    box-sizing: border-box;
  }

  input {
    outline: none;
    box-shadow: none;
    border: none;
    width: 80%;
    overflow: hidden;
    background: transparent;
  }

  svg {
    position: absolute;
    right: 12px;
    top: 10px
  }
}

.times {
  background: #fff;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  transition: .3s ease all;
  position: absolute;
  top: 80%;
  button {
    font-family: 'graphic';
    background: transparent;
    border: none;
    outline: none;
    color: #666666;
    font-size: 14px;
    text-align: center;
    width: 100%;
    display: block;
    cursor: pointer;
    &:hover {
      color: #000;
    }
  }
}

.top {
  border-top: 1px solid #DBDBDB;
  padding: 12px 0;
}

.time {
  span {
    position: relative;
    height: 32px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    cursor: pointer;
    transition: .3s ease all;
    &:hover {
      background-color: rgba(#000, .1);
    }
  }
}

.timesWrapper {
  display: flex;
}

.timesInner {
  width: 50%;
}

.active {
  background: #FFFFFF;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  input {
    border: none;
  }
  .input {
    border-color: transparent;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .times {
    visibility: visible;
    opacity: 1;
    z-index: 2;
    background: #FFFFFF;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}
