.button-outlined {
  font-family: 'sf';
  height: 38px;
  display: flex;
  align-items: center;
  padding: 22px 38px;
  outline: none;
  border-radius: 6px;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  border-width: 1px;
  border-style: solid;
  background: #FFF;
  transition: all .3s ease;
  box-sizing: border-box;

  &:hover {
  opacity: .7;
}


  &--rounded {
    border-radius: 96px;
  }

  &--orange {
    border-color: #FFCF5C;
  }

  &--green {
    border-color: #73D389;
  }
  &--red {
    border-color: #EB5757;
  }
  &--transparent {
    border-color: transparent;
  }

  &--disabled {
    opacity: .7;
  }

  .button-outlined {
    &__text {
      font-weight: 400;

      line-height: 16px;
      font-size: 16px;


      &--green {
        color: #73D389;
        background: -webkit-linear-gradient(#73D389, #4EB78B);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &--red {
        color: #EB5757;
      }

      &--black {
        color: #1D1F23;
      }

      &--hidden {
        visibility: hidden;
      }
    }

    &__prefix {
      min-width: 14px;
      min-height: 14px;
      margin-right: 14px;

      img {
        max-width: 100%;
      }
      svg {
        max-width: 100%;
      }
    }

    &__spinner {
      position: absolute;
      width: 22px;
      height: 22px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
