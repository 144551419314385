@font-face {
  font-family: "sf";
  src: url("assets/fonts/SF/SFProDisplay-Regular.eot");
  src: url("assets/fonts/SF/SFProDisplay-Regular.woff2") format("woff2"),
  url("assets/fonts/SF/SFProDisplay-Regular.woff") format("woff"),
  url("assets/fonts/SF/SFProDisplay-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "sf";
  src: url("assets/fonts/SF/SFProDisplay-Medium.eot");
  src: url("assets/fonts/SF/SFProDisplay-Medium.woff2") format("woff2"),
  url("assets/fonts/SF/SFProDisplay-Medium.woff") format("woff"),
  url("assets/fonts/SF/SFProDisplay-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "sf";
  src: url("assets/fonts/SF/SFProDisplay-Light.eot");
  src: url("assets/fonts/SF/SFProDisplay-Light.woff2") format("woff2"),
  url("assets/fonts/SF/SFProDisplay-Light.woff") format("woff"),
  url("assets/fonts/SF/SFProDisplay-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "graphic";
  src: url("assets/fonts/graphic/GraphikLCG-Regular.eot");
  src: url("assets/fonts/graphic/GraphikLCG-Regular.woff2") format("woff2"),
  url("assets/fonts/graphic/GraphikLCG-Regular.woff") format("woff"),
  url("assets/fonts/graphic/GraphikLCG-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "graphic";
  src: url("assets/fonts/graphic/GraphikLCG-Medium.eot");
  src: url("assets/fonts/graphic/GraphikLCG-Medium.woff2") format("woff2"),
  url("assets/fonts/graphic/GraphikLCG-Medium.woff") format("woff"),
  url("assets/fonts/graphic/GraphikLCG-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "gilroy";
  src: url("assets/fonts/gilroy/Gilroy-Medium.eot");
  src: url("assets/fonts/gilroy/Gilroy-Medium.woff2") format("woff2"),
  url("assets/fonts/gilroy/Gilroy-Medium.woff") format("woff"),
  url("assets/fonts/gilroy/Gilroy-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, 'sf', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.ReactModal__Overlay {
  transition: opacity 500ms ease-in-out;
  opacity: 0;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.google-visualization-tooltip{
  border:none !important;
  background-color: inherit!important;
  box-shadow: none!important;
}


.lineChartContainer {
  box-shadow: 1px 1px 10px rgba(0,0,0,0.5);
  width: 174px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 12px;
  color: red;
}

.lineChartContainer span {
  font-family: graphic, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #4EB78B;
}

.lineChartContainer p {
  margin: 0;
  font-family: graphic, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: rgba(53, 70, 85, 0.79);
}


.donutChartContainer_purple {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 200px;
  background: linear-gradient(180deg, #705AFB 0%, #5769EB 100%);
  border: 4px solid #FFFFFF;
  box-shadow: 0px 4px 10px rgba(51, 51, 51, 0.18);
}

.donutChartContainer_green {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 200px;
  background: linear-gradient(180deg, #73D389 0%, #4EB78B 100%);
  border: 4px solid #FFFFFF;
  box-shadow: 0px 4px 10px rgba(51, 51, 51, 0.33);
}

.donutChartContainer_gray {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 200px;
  background: #DCDCDB;
  border: 4px solid #FFFFFF;
  box-shadow: 0px 4px 10px rgba(51, 51, 51, 0.33);
}

.donutChartContainer_purple span, .donutChartContainer_green span, .donutChartContainer_gray span {
  font-family: graphic, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #FFFFFF;
}

.modal {
  display: none;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
  overflow-y: auto;
  overflow-x: hidden;

  &--open {
    display: flex;
    flex-direction: column;
  }
}

#root {
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#root-modal {
  position: relative;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: transparent;
}

.notification__item--success {
  background: linear-gradient(180deg, #73D389 0%, #4EB78B 100%);
  border-left: 8px solid #4EB78B !important;
}


.table {
  width: 100%;
  max-width: 100%;
  border-collapse: separate;
  border-spacing: 0 0.5em;
  position: relative;
  position: relative;

  &.loading {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(#fff, .3);
      z-index: 99;
    }
  }

  tr {
    font-family: sf, serif;
    color: #333333;
    font-style: normal;
    height: 50px;
    &:hover {
      background: #F6F6F5;
    }
  }

  thead {
    background: #F8F8F8;
    th {
      cursor: pointer;
      font-weight: 500;
      &:first-child {
        border-radius: 6px 0 0 6px;
      }
      &:last-child {
        border-radius: 0 6px 6px 0;
      }
    }
  }

  tbody {
    tr {
      height: 60px;
    }

    td {
      cursor: pointer;
      border-top: 1px solid #E7E7E6;
      border-bottom: 1px solid #E7E7E6;
      &:first-child {
        border-radius: 4px 0 0 4px;
        border-left: 1px solid #E7E7E6;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
        border-right: 1px solid #E7E7E6;
      }
      white-space: pre-wrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  th, td {
    text-align: left;
    padding-left: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
  }
}

.btn-trash {
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  background: #FA6262;
  border-radius: 4px;
  padding: 9px 15px;
  font-weight: 600;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  min-height: 31px;

  &:disabled {
    opacity: .6;
  }

  span {
    margin-left: 13px;
  }

  svg {
    fill: #fff;
  }
}

.data-view {
  display: flex;

  &__column {
    flex-shrink: 0;
    flex-grow: 1;
    &:not(:first-child) {
      margin-left: 54px;
    }
  }
  &__item {
    font-family: 'SF Pro Display';
    color: #1D1F23;
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin: 10px 0;
    }

    &-title {
      display: inline-block;
      max-width: 180px;
      width: 100%;
      b {
        font-family: "graphic", serif;
        font-size: 18px;
        line-height: 21px;
        font-weight: 500;
      }
    }
    &-data {
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      background: #F6F6F5;
      border-radius: 6px;
      width: 277px;
      height: 59px;
      display: flex;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      position: relative;
      overflow: hidden;
      box-sizing: border-box;
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &--loading &__item-data {
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
                      90deg,
                      rgba(#fff, 0.2) 0,
                      rgba(#fff, 0.5) 20%,
                      rgba(#fff, 0.8) 90%,
                      rgba(#fff, 0)
      );
      animation: shimmer 2s infinite;
      content: '';
    }

    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
  }

  &__copyBtn {
    border: none;
    background-color: transparent;
    cursor: pointer;
    svg {
      transition: .3s ease all;
    }
    fill: white;
    stroke: #AFAFAB;
    &:hover {
      stroke: #73D389;
    }
  }
}

.ReactModal__Content, .hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 0;
  }
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 0;
  }
  :hover::-webkit-scrollbar-track {
    background-color: transparent;
  }
  :hover::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 0;
  }
}
.with-scrollbar {
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: gray;
    border-right: 4px white solid;
    background-clip: padding-box;
    border-radius: 2px;
  }
  &:hover::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: gray;
    border-right: 4px white solid;
    background-clip: padding-box;
    border-radius: 2px;
  }
}