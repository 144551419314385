.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 10px;
}

.container * {
    transition: .3s ease all;
}

.container .btn {
    padding: 2px 64px 2px 28px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: .3s ease background;
    position: relative;
    outline: none;
}

.container .link {
    padding-left: 63px;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.container .link span {
    min-width: 68px;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 42px;
    color: #AFAFAB;
    letter-spacing: -0.115976px;
}

.container .link.linkActive span {
    background: linear-gradient(180deg, #73D389 0%, #4EB78B 100%);
    background: -webkit-linear-gradient(180deg, #73D389 0%, #4EB78B 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.container .link span:hover {
    color: white
}

.container .link.activeTab {
    padding: 2px 64px 2px 28px;
    background: linear-gradient(180deg, #73D389 0%, #4EB78B 100%);
    border-radius: 8px;
}

.container .btn span {
    min-width: 104px;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 42px;
    color: #AFAFAB;
    letter-spacing: -0.115976px;
    text-align: left;
}

.container .btn i{
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(-93deg);
    right: 20px;
}

.container .btn.activeTab i{
    transform: translateY(-50%) rotate(0);
}

.container .btn span:hover {
    color: white
}


.container .btn img {
    margin-right: 20px;
}


.container .btn.activeTab {
    padding: 2px 64px 2px 28px;
    background: linear-gradient(180deg, #73D389 0%, #4EB78B 100%);
    border-radius: 8px;
}

.container .btn.activeTab span {
    color: white;
}

.wrapper {
    overflow: hidden;
    width: 100%;
    max-height: 0;
    transition: .5s ease-out max-height;
}

.collapsed {
    max-height: 500px;
}
