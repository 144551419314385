.container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.container h2{
    margin: 10px 0 0 0 ;
    font-family: graphic, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    /* identical to box height, or 156% */
    letter-spacing: -0.02em;
}

.periods{
    display: flex;
    align-items: center;
}

.periods p{
    cursor: pointer;
    font-family: graphic, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: rgba(87, 100, 120, 0.73);
}