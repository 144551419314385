.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 1px solid rgba(78, 183, 139, 0.41);
    border-bottom: 1px solid rgba(78, 183, 139, 0.41);
    padding: 24px 0;
}

.container p {
    margin: 0 0 3px 0;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    text-align: center;
    letter-spacing: -0.115976px;
    color: #FFFFFF;
}

.container span {
    margin-top: 3px;
    font-weight: 500;
    font-size: 19px;
    line-height: 23px;
    /* identical to box height */
    color: #FFFFFF;
}