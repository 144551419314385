.container {
  font-family: 'graphic';

  border-top: 1px solid #E7E7E6;
  padding-top: 24px;
  margin-bottom: 24px;

  & > div {
    display: flex;
    align-items: flex-start;
    max-width: 595px;
  }

  &:first-child {
    border-top: 0;
    padding-top: 0;
  }
}

.logo {
  margin-right: 21px;
  & > img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    overflow: hidden;

    background-color: #BDBDBD;
  }
}

.body {
  width: 100%;
}

.name {
  font-weight: 500;
  color: #1D1F23;
}

.date {
  color: rgba(#000, .6);
  margin: 10px 0 12px;
  font-size: 16px;
}

.rating {

}

.reviewerText {
  color: #1D1F23;
  margin-top: 8px;
  line-height: 24px;
  font-size: 16px;
  word-break: break-all;
}

.btn {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-top: 13px;
  outline: none;
  transition: all .3s ease;

  &:hover {
    opacity: .6;
  }
  span {
    font-size: 16px;
    line-height: 20px;
  }
  svg {
    margin-right: 11px;
  }
}

.answerBtn {
  background: -webkit-linear-gradient(#73D389, #4EB78B);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  svg {
    fill: #4EB78B;
  }
}

.changeBtn {
  color: #AFAFAB;
  svg {
    fill: #AFAFAB;
  }
}

.answer {
  display: flex;
  margin-top: 10px;
  margin-bottom: 3px;
  div:first-child {
    color: #4EB78B;
    margin-right: 12px;
    background: -webkit-linear-gradient(#73D389, #4EB78B);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  div:last-child {
    letter-spacing: -0.02em;
    font-size: 16px;
    color: #1D1F23;
  }
}
