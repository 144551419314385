.container {
  margin: 58px 50px;
  text-align: center;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header span {
  margin-bottom: 22px;
  align-self: center;
  background: rgba(107, 205, 138, 0.05);
  border-radius: 8px;
  color: #59BF8A;
  padding: 19px;
  font-family: sf, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  max-width: 325px;
  width: 100%;
}

.title {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 58px;
}

.btn {
  margin-top: 37px;
  max-width: 100%;
  justify-content: center;
  border: none;
  width: 100%;
}

.block {
  margin-bottom: 20px;

  p {
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    text-align: left;
  }

  span {
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    color: #53BB7E;
  }
}