.container{
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 24px;
    position: relative;
    padding: 33px 79px;
    height: 100%;
}

.bonusButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bonusButton {
    align-self: flex-start;
}

.bonusButton button {
    cursor: pointer;
    border-radius: 8px;
    padding: 13px 48px;
    font-family: sf,serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    border: none;
    outline: none;
}

.dates{
    margin: 24px 0 ;
    display: flex;
    align-items: center;
    align-self: flex-start;
    width: 100%;
}
