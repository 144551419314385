.uploaders {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #E4E7EB;
  display: flex;
  justify-content: space-between;
}

.banners {
  margin-bottom: 24px;
  display: flex;
  flex-wrap: wrap;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.img {
  width: 210px;
  height: 160px;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all .3s ease;
  }

  &:hover:after {
    background-color: rgba(#000, .3);
  }
}

.img:hover > .deletePhotoBtn {
  z-index: 2;
}

.deletePhotoBtn {
  font-family: 'graphic';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  background-color: #b21e1e;
  color: #fff;
  text-shadow: none;
  border: none;
  outline: none;
  padding: 10px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 3px;
  transition: all .3s ease;
  opacity: .5;
  z-index: -1;

  &:hover {
    opacity: 1;
  }


  &:hover > button {

  }
}

.dropzone {
  padding: 66px;
}
