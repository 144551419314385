.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 9px;
}

.container h1 {
    margin: 0;
    font-family: graphic, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #333333;
    width: 100%;
    padding-bottom: 18px;
    border-bottom: 1px solid #ECECEC;
}

.datesPickers{
    display: flex;
    align-items: center;
    margin-top: 24px;
}

.bottomCharts {
    margin-top:58px;
    width: 100%;
    display: flex;
    align-items: flex-start;
}

.chartBar{
    align-items: flex-start;
    display:flex;
    flex-direction:column;
}

.chartBar p{
    font-family: graphic,serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #1D1F23;
}

.donutChart{
    margin-left: 71px;
}