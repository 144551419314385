.text-field {
  &__input-container {
    background: #FFFFFF;
    border: 1px solid #E7E7E6;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    padding: 0 16px;
    align-items: center;
    min-height: 44px;
    flex-grow: 1;
    transition: all .3s ease;

    &--green {
      border-color: #73D389;
    }

    &--error {
      border-color: #F34C4C;
    }

    &--disabled {
      border-color:  #999;
      opacity: .3;
    }
  }

  &__label-container {
    display: flex;
    flex-direction: column;

    &--inline {
      flex-direction: row;
      align-items: center;

      .text-field__label {
        margin: 0 38px 0 0;
        flex-shrink: 0;
        flex-grow: 0;
      }
    }
  }

  &__label {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 10px 0;
    color: #1D1F23;
    i {
      color: tomato;
    }
  }

  &__input {
    height: 22px;
    color: #121212;
    flex-grow: 1;
    flex-shrink: 1;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    outline: none;
    padding: 0;
    margin: 0;
    border: none;
    max-width: 100%;
    &::placeholder {
      font-family: 'graphic';
      font-weight: 400;
      color: rgba(#000, .5);
      font-size: 16px;
    }
  }

  &__icon {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }

  &__prefix {
    padding-right: 6px;
    box-sizing: border-box;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;

    > p {
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
      color: #121212;
      margin: 0;
      padding: 0;
    }
  }

  &__postfix {
    margin-right: -8px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
  }

  &__error {
    text-align: left;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    margin: 5px 5px 0 5px;
    color: #F34C4C;
  }

  + .text-field {
    margin-top: 16px;
  }
}
