.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 56px 28px 20px 28px;
    position: relative;
}

.container input {
    width: 455px;
    align-self: center;
    padding: 21px 0 21px 32px;
    border: 1px solid #5CC28B;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.75em;
    line-height: 33px;
    border-radius: 8px;
    color: #333333;
    outline: none;
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.closeModal{
    cursor: pointer;
    position: absolute;
    top:28px;
    right: 28px;
}

.backButton{
    cursor: pointer;
    position: absolute;
    top:36px;
    left: 29px;
}


.default {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: sf, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 26px;
    color: #1D1F23;
    padding: 0 5vw;
}

.disabled {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: sf, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 26px;
    color: #A5A5A7;
    padding: 0 5vw;
}


.marked {
    padding: 0 5vw;
    background: #EDF8F3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: #1D1F23;
}

.notRegistered {
    display: flex;
    align-items: flex-start;
    max-width: 467px;
    margin: 17px auto 0;
    p {
        font-weight: 300;
        font-size: 18px;
        color: #1D1F23;
        margin: 0 0 5px 0;
    }
    img {
        width: 18px;
        height: 18px;
        flex-shrink: 0;
        margin-right: 10px;
    }

}

.container button {
    align-self: center;
    margin-top: 44px;
    cursor: pointer;
    background: linear-gradient(180deg, #73D389 0%, #4EB78B 100%);
    border-radius: 8px;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5em;
    line-height: 20px;
    padding: 20px 56px;
    color: #FFFFFF;
    outline: none;
    border: none;
}


.errorMessage{
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.errorMessage span{
    color:red;
}