.bonusButton button {
    cursor: pointer;
    border-radius: 6px !important;
    border: 1px solid #62C68A !important;
    padding: 13px 48px;
    font-family: sf,serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: green;
    border: none;
    outline: none;
    background-color: #fff;
    outline: none;
    color: #62C68A;
   
}