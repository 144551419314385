.container input{
    padding: 17px 48px;
    min-width: 507px;
    font-size: 16px;
    border: 1px solid #E7E7E6;
    box-sizing: border-box;
    border-radius: 4px;
    background: url('../../assets/searchIcon.png') no-repeat scroll 15px 15px;
    outline: none;
    margin-right: 10px;
}
