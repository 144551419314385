.container {
  border-bottom: 1px solid #E4E7EB;
  padding-bottom: 16px;
  margin-bottom: 24px;
  img {
    height: 32px;
    width: 32px;
  }
}

.inputWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.input {
  width: 95%;
}

.label {
  font-family: 'graphic';
  font-size: 18px;
  color: #1D1F23;
  margin-bottom: 12px;
  display: block;
}