.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 508px;
}

.container p {
    margin: 0 0 4px 0;
    font-family: graphic, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #77797B;
}

.container span {
    font-family: graphic, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #1D1F23;
    margin-bottom: 14px;
    word-break: break-word;
}

.container img {
    width: 187px;
    border-radius: 12px;
}