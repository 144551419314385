.container {
}

.dropzone {
  padding: 32px 66px;
}

.card {
  width: 100%;
  max-width: 340px;
  padding: 20px 20px 26px 20px;
  border-radius: 24px;
  color: #fff;
  transition: .3s ease all;
  background-color: rgba(#000, .6);

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.cardHeader {
  display: flex;
  align-items: center;
  img {
    width: 38px;
    height: 38px;
    border-radius: 38px;
    display: block;
  }
}

.cardTitle {
  padding-left: 10px;

  span:first-child {
    display: block;
    font-weight: 600;
  }
}

.cardBody {
  margin: 20px 0;
  b {
    display: block;
    font-size: 32px;
  }
  span {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
  }
}

.cardFooter {
  span {
    font-weight: 500;
    font-size: 14px;
  }
}

.modal {
  text-align: center;

  p {
    font-weight: 500;
    font-size: 22px;
    margin-top: -24px;
  }
  button {
    margin: 0 auto;
  }
}
