.container {
  margin: 58px 50px;

  textarea {
    width: 100%;
    height: 110px;
    resize: vertical;
    border: 1px solid #4EB78B;
    outline: none;
    font-size: 16px;
    border-radius: 6px;
    font-family: 'graphic';
    color: #1D1F23;
    padding: 23px 19px;
    box-sizing: border-box;
    margin-top: 15px;
  }
}

.title {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 58px;
  text-align: center;
}

.btn {
  text-align: center;
  margin-top: 24px;
  max-width: 100%;
  justify-content: center;
  border: none;
  width: 100%;
}

.dropdownStyle {
  border-radius: 0;
}