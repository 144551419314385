.modal {
  max-width: 1040px;
  min-height: 711px;
  margin: 20px 0;
  padding: 31px 43px 63px;
  background: #FFFFFF;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  position: relative;
}

.closeModal {
  z-index: 2;
  cursor: pointer;
  position: absolute;
  top:28px;
  right: 28px;
}

.container{
  width: 100%;
}

.infoContainer {
  display: flex;
  justify-content: space-between;
}

.info {
  &:not(:first-child) {
    margin-left: 53px;
  }
}

.infoItem {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.infoItemTitle {
  max-width: 163px;
  margin-right: 22px;
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #1D1F23;
}

.infoItemData {
  width: 277px;
  padding: 19px 18px;
  color: #1D1F23;
  background: #F6F6F5;
  border-radius: 6px;
  overflow: hidden;
  overflow-wrap: anywhere;
}

.infoItemComment {
  width: 277px;
  padding: 19px 34px;
  color: #1D1F23;
  background: #F6F6F5;
  border-radius: 6px;
  height: 153px;
  overflow: auto;

  & > div {
    overflow: auto;
  }
}

.green {
  color: #4EB78B;
}