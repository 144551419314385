.container {
}

.block {
  font-family: 'graphic';
  padding-bottom: 24px;
  position: relative;
  &__text {
    padding: 0;
    margin: 8px 0 0 0;
    font-size: 16px;
    color: #AFAFAB;
  }
  &__right-text {
    position: absolute;
    top: 43px;
    left: 173px;
    color: #1D1F23;
    font-size: 16px;
  }
  &__btn {
    position: absolute;
    top: 30px;
    left: 233px;
    padding-top: 21px;
    padding-bottom: 21px;
  }
}

.open {
  background-color: transparent;
  border: none;
  padding: 0 10px;
  cursor: pointer;
}
.open:hover path {
  fill: #73D389;
  transition: 0.3s ease;
}
.edit {
  background-color: transparent;
  border: none;
  padding: 0 10px;
  cursor: pointer;
}
.edit:hover path {
  fill: #73D389;
}
.delete {
  background-color: transparent;
  border: none;
  padding: 0 10px;
  cursor: pointer;
}
.delete:hover path {
  transition: 0.3s ease;
  fill: tomato;
}

.footer {
  margin-top: 16px;
  padding-top: 24px;
  border-top: 1px solid #E4E7EB;
  display: flex;
  button:first-child {
    margin-right: 18px;
  }
}

.container textarea {
  font-family: graphic, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: black;
  margin: 0;
  width: 100%;
  max-width: 705px;
  height: 100px;
  padding: 11px 20px;
  border: 1px solid #E7E7E6;
  border-radius: 6px;
  outline: none;
  resize: none;
}

.modal {
  text-align: center;

  p {
    font-weight: 500;
    font-size: 22px;
    margin-top: -24px;
  }
  button {
    margin: 0 auto;
  }
}

.btn {
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
