.container {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 24px;
    position: relative;
    padding: 33px 79px;
    height: 100%;
}

.dataWrapper {
    display: flex;
    align-items: center;
}

.itemTitle, .itemValue {
    display: flex;
    flex-direction: column;
}

.itemTitle p {
    white-space: nowrap;
    margin: 0;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.125em;
    line-height: 21px;
    color: #1D1F23;
}

.itemValue span {
    font-family: sf, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.125em;
    line-height: 21px;
    color: #1D1F23;
    background: #F6F6F5;
    border-radius: 6px;
    padding: 19px 0 19px 34px;
    width: 13.9vw;
}

/*.container ul {*/
/*    width: 100%;*/
/*    list-style-type: none;*/
/*    margin: 0;*/
/*    padding: 0;*/
/*}*/

/*.container ul li {*/
/*    margin: 18px 0;*/
/*    font-size: 18px;*/
/*    line-height: 21px;*/
/*    color: #1D1F23;*/
/*}*/

/*.container ul li span {*/
/*    font-weight: 500;*/
/*    font-size: 18px;*/
/*    line-height: 21px;*/
/*    color: #1D1F23;*/
/*}*/

.button {
    margin-top: 53px;
    outline: none;
    background: inherit;
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    border-radius: 4px;
    color: #1D1F23;
    text-align: center;
    padding: 10px 0;
    border: 1px solid #5AC08A;
    width: 200px;
    transition: 0.3s;
}

.container button:hover {
    transition: 0.3s;
    background: #5AC08A;
    color: #FFFFFF;
}

.container h3 {
    margin-top: 37px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #1D1F23;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px 20px 0 80px;
    box-sizing: border-box;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.notRegistered {
    text-align: center;
    margin-bottom: 14px;
div {
    max-width: 490px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
}
img {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    margin-right: 10px;
}
p {
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 500;
    color: #4EB78B;
}
span {
    text-align: left;
    font-weight: 300;
    font-size: 18px;
    color: #1D1F23;
}
}

.header p {
    align-self: center;
    margin: 0;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5em;
    line-height: 29px;
}

.header span {
    margin: 28px 0 0 0;
    align-self: center;
    background: linear-gradient(0deg, rgba(115, 211, 137, 0.04) 0%, rgba(78, 183, 139, 0.04) 100%);
    border-radius: 8px;
    color: #59BF8A;
    padding: 19px 100px;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.375em;
    line-height: 26px;
}

.points, .amount, .getPoints {
    display: flex;
    flex-direction: column;
}

.payment {
    user-select: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 49px;

img {
    transition: .3s ease all;
    border-radius: 5px;
    cursor: pointer;
    max-width: 100%;
    position: relative;
    height: 100%;
}

& > div {
      position: relative;
      height: 46px;
      cursor: pointer;

&:before {
     content: '';
     position: absolute;
     left: 0;
     top: 0;
     right: 0;
     bottom: 0;
     z-index: 10;
     border: 3px solid transparent;
     border-radius: 3px;
 }
}
.active:before {
    border-color: #73D389;
    background: rgba(#fff, .3);
}
.active {

}
}

.points p {
    margin-left: 49px;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.125em;
    line-height: 21px;
    color: #333333;
    align-self: flex-start;
}

.points span {
    align-self: center;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 3em;
    line-height: 57px;
    color: #59BF8A;
}


.amount p {
    margin-left: 49px;
    align-self: flex-start;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.125em;
    line-height: 21px;
    color: #333333;
}

.amount input {
    align-self: center;
    padding: 21px 0 21px 32px;
    border: 1px solid #E7E7E6;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.75em;
    line-height: 33px;
    color: #ADADAD;
    outline: none;
    -moz-appearance: textfield;
&::-webkit-outer-spin-button,
&::-webkit-inner-spin-button {
     -webkit-appearance: none;
     margin: 0;
 }
}


.getPoints p {
    margin-left: 49px;
    align-self: flex-start;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.125em;
    line-height: 21px;

    color: #333333;
}

.getPoints button {
    font-family: 'graphic';
    font-weight: 500;
    margin-left: 49px;
    align-self: flex-start;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 18px;
    padding: 0;
    outline: none;
    margin-top: 0;

    color: #4EB78B;
}

.getPoints input {
    width: 455px;
    align-self: center;
    padding: 21px 0 21px 32px;
    border: 1px solid #5CC28B;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.75em;
    line-height: 33px;
    border-radius: 8px;
    color: #333333;
    outline: none;
    -moz-appearance: textfield;
&::-webkit-outer-spin-button,
&::-webkit-inner-spin-button {
     -webkit-appearance: none;
     margin: 0;
 }
}


.bonusesAmount {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bonusesAmount p {
    font-family: sf, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1em;
    line-height: 19px;
    color: #858585;
}

.bonusesAmount p span {
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 19px;
    color: #333333;
}

.bonusesAmount button {
    cursor: pointer;
    margin-right: 49px;
    background: inherit;
    border: none;
    outline: none;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 19px;
    color: #4EB78B;
}

.sendButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.sendButton button {
    cursor: pointer;
    margin-top: 24px;
    background: linear-gradient(180deg, #73D389 0%, #4EB78B 100%);
    border-radius: 8px;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5em;
    line-height: 20px;
    padding: 22px 66px;
    color: #FFFFFF;
    outline: none;
    border: none;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px 20px 0 80px;
    box-sizing: border-box;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.notRegistered {
    text-align: center;
    margin-bottom: 14px;
div {
    max-width: 490px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
}
img {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    margin-right: 10px;
}
p {
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 500;
    color: #4EB78B;
}
span {
    text-align: left;
    font-weight: 300;
    font-size: 18px;
    color: #1D1F23;
}
}

.header p {
    align-self: center;
    margin: 0;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5em;
    line-height: 29px;
}

.header span {
    margin: 28px 0 0 0;
    align-self: center;
    background: linear-gradient(0deg, rgba(115, 211, 137, 0.04) 0%, rgba(78, 183, 139, 0.04) 100%);
    border-radius: 8px;
    color: #59BF8A;
    padding: 19px 100px;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.375em;
    line-height: 26px;
}

.points, .amount, .getPoints {
    display: flex;
    flex-direction: column;
}

.payment {
    user-select: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 49px;

img {
    transition: .3s ease all;
    border-radius: 5px;
    cursor: pointer;
    max-width: 100%;
    position: relative;
    height: 100%;
}

& > div {
      position: relative;
      height: 46px;
      cursor: pointer;

&:before {
     content: '';
     position: absolute;
     left: 0;
     top: 0;
     right: 0;
     bottom: 0;
     z-index: 10;
     border: 3px solid transparent;
     border-radius: 3px;
 }
}
.active:before {
    border-color: #73D389;
    background: rgba(#fff, .3);
}
.active {

}
}

.points p {
    margin-left: 49px;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.125em;
    line-height: 21px;
    color: #333333;
    align-self: flex-start;
}

.points span {
    align-self: center;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 3em;
    line-height: 57px;
    color: #59BF8A;
}


.amount p {
    margin-left: 49px;
    align-self: flex-start;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.125em;
    line-height: 21px;
    color: #333333;
}

.amount input {
    align-self: center;
    padding: 21px 0 21px 32px;
    border: 1px solid #E7E7E6;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.75em;
    line-height: 33px;
    color: #ADADAD;
    outline: none;
    -moz-appearance: textfield;
&::-webkit-outer-spin-button,
&::-webkit-inner-spin-button {
     -webkit-appearance: none;
     margin: 0;
 }
}


.getPoints p {
    margin-left: 49px;
    align-self: flex-start;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.125em;
    line-height: 21px;

    color: #333333;
}

.getPoints button {
    font-family: 'graphic';
    font-weight: 500;
    margin-left: 49px;
    align-self: flex-start;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 18px;
    padding: 0;
    outline: none;
    margin-top: 0;

    color: #4EB78B;
}

.getPoints input {
    width: 455px;
    align-self: center;
    padding: 21px 0 21px 32px;
    border: 1px solid #5CC28B;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.75em;
    line-height: 33px;
    border-radius: 8px;
    color: #333333;
    outline: none;
    -moz-appearance: textfield;
&::-webkit-outer-spin-button,
&::-webkit-inner-spin-button {
     -webkit-appearance: none;
     margin: 0;
 }
}


.bonusesAmount {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bonusesAmount p {
    font-family: sf, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1em;
    line-height: 19px;
    color: #858585;
}

.bonusesAmount p span {
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 19px;
    color: #333333;
}

.bonusesAmount button {
    cursor: pointer;
    margin-right: 49px;
    background: inherit;
    border: none;
    outline: none;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 19px;
    color: #4EB78B;
}

.sendButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.sendButton button {
    cursor: pointer;
    margin-top: 24px;
    background: linear-gradient(180deg, #73D389 0%, #4EB78B 100%);
    border-radius: 8px;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5em;
    line-height: 20px;
    padding: 22px 66px;
    color: #FFFFFF;
    outline: none;
    border: none;
}

.isSent {

}


.modal {
    width: 589px;
    min-height: 590px;
    background: #FFFFFF;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    position: relative;
}

.closeModal{
    z-index: 2;
    cursor: pointer;
    position: absolute;
    top:28px;
    right: 28px;
}

.modalContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px;
    box-sizing: border-box;
}

.modalContainer .p{
    font-weight: 500;
    font-size: 18px;
}

.modalContainer .span{
    font-family: 'graphic';
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: rgba(29, 31, 35, 0.55);
    opacity: 0.6;
}

.bonuses {
    padding-right: 17px;
    padding-left: 17px;

    button {
        margin: 50px auto 0;
    }
}

.bonusesHeader {
    color: #1D1F23;
    font-weight: 300;
    font-size: 18px;
    display: flex;
    margin-bottom: 24px;

    svg {
        flex-shrink: 0;
        margin-top: 4px;
    }
    p {
        padding: 0;
        margin: 0 0 0 10px;
    }
}

.subTitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #333333;
    margin: 0 0 14px 0;
}

.inputBonus {
    color: #73D389;
    margin-top: 20px;
    background-color: #73D389;
    background-image: linear-gradient(to bottom, #73D389, #4EB78B);
    -webkit-background-clip: text;

    font-weight: 500;
    font-size: 48px;
    outline: none;
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    border: none;
}

.bonusInput {
    width: 491px;
    height: 75px;
    border: 1px solid #E7E7E6;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    color: #333333;
}

.green {
    border-color: #73D389;
    margin-bottom: 10px;
}

.cash {
    span {
        color: #333333;
        opacity: 0.6;
        font-size: 16px;
    }
    b {
        font-weight: 500;
        font-size: 16px;
        color: #333333;
    }
}

.bonusesResult {
    & > div {
        display: flex;
        padding: 12px 39px;
        box-sizing: border-box;
        & > span {
            font-size: 22px;
            line-height: 26px;
            color: #1D1F23;
            display: inline-block;
            &:nth-child(2) {
                max-width: 298px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &:first-child {
                width: 50%;
                margin-right: 39px;
            }
        }
        b {
            font-size: 22px;
            line-height: 26px;
            color: #1D1F23;
            margin-right: 39px;
            width: 50%;
        }
    }
}

.bonusesResultItem {
    margin-top: 20px;
    padding: 26px 39px !important;
    left: 484px;
    top: 362px;
    background: rgba(#4EB78B, .1);
    border-radius: 12px;
}

.textArea {
    height: 130px;
    width: 100%;
    box-sizing: border-box;
    font-family: graphic, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: black;
    padding: 11px 20px;
    border: 1px solid #97D6B9;
    border-radius: 6px;
    outline: none;
    resize: none;
}

.btn {
    text-align: center;
    justify-content: center;
    width: 260px;
    height: 50px;
}

.greenBtn {
    font-family: 'graphic';
    font-size: 16px;
    color: #73D389;
    background: -webkit-linear-gradient(#73D389, #4EB78B);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}