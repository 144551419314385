.container {
  background: rgba(115, 211, 137, 0.02);
  margin: 0 32px 32px 0;
  transition: .3s ease all;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: #fff;
  }
}

.wrapper {
  transition: .3s ease all;
  border: 1px solid #73D389;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 22px 25px;
  max-width: 446px;

  &:hover {
    border-color: rgba(#73D389, .4);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 23px;
}

.headerLeft {
  display: flex;
  align-items: center;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    background: #73D389;
    border-radius: 6px;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    flex-shrink: 0;
  }
  p {
    display: flex;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #333333;
    order: 1;
    flex-grow: 0;
    margin-left: 20px;
    word-break: break-word;
    width: 180px;
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.headerRight {
  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    svg {
      transition: .3s ease all;
    }
    &:first-child {
      fill: white;
      stroke: #AFAFAB;
      &:hover {
        stroke: #73D389;
      }
    }
    &:last-child {
      svg {
        fill: #AFAFAB;
        &:hover {
          fill: tomato;
        }
      }
    }
  }
}

.body {
  display: flex;
  justify-content: space-between;
}

.bodyColumn {
  width: 33.3%;
  span {
    font-size: 14px;
    line-height: 17px;
    color: rgba(29, 31, 35, 0.5);
  }
  div {
    display: flex;
    align-items: center;
    margin-top: 5px;
    span {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #333333;
      margin-left: 12px;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.footer {
  margin-top: 23px;
  span {
    font-size: 14px;
    line-height: 17px;
    color: rgba(29, 31, 35, 0.5);
  }
}
