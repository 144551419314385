.container {
  border: 1px solid #E7E7E6;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  cursor: pointer;

  & > div {
    padding: 18px 18px;
    height: 56px;
    width: 300px;
    box-sizing: border-box;
  }

  button {
    position: absolute;
    outline: none;
    background-color: transparent;
    border: none;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    position: absolute;
    width: 100%;
    border-left: 1px solid #E7E7E6;
    border-right: 1px solid #E7E7E6;
    box-sizing: border-box;
    left: 0;
    right: 0;
    top: 58px;
    opacity: 0;
    z-index: -10;
    transition: .1s ease opacity;
    background-color: #fff;



    li {
      border-bottom: 1px solid #E7E7E6;
      padding: 16px 18px;
      box-sizing: border-box;
      &:hover {
        background-color: rgba(#E7E7E6, .9);
      }
    }
  }

  .opened {
    opacity: 1;
    z-index: 10;
  }
}
