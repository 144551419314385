.container {
    display: flex;
}

.tab {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.tab:first-child {
    margin-right: 40px;
}

.tab:nth-child(2) {
    margin-right: 40px;
}

.item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}


.item span {
    font-family: graphic, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1em;
    line-height: 28px;
    /* identical to box height, or 156% */
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #1D1F23;
    white-space: nowrap;
}


.item div {
    display: flex;
}

.item div span {
    margin: 12px 0 0 5px;
}

.green_p {
    flex-shrink: 0;
    height: auto;
    margin: 10px 0;
    font-family: graphic, serif;
    color: #73D389;
    background-color: #73D389;
    background-image: linear-gradient(to bottom, #73D389, #4EB78B);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-style: normal;
    font-weight: 600;
    font-size: 3.7vh;
    letter-spacing: -0.02em;
}

.purple_p {
    flex-shrink: 0;
    height: auto;
    margin: 10px 0;
    font-family: graphic, serif;
    color: #705AFB;
    background-color: #705AFB;
    background-image: linear-gradient(to bottom, #705AFB, #5769EB);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-style: normal;
    font-weight: 600;
    font-size: 1.9vw;
    letter-spacing: -0.02em;
}
