
.emptyData {
    width: 100%;
}

.emptyData h1 {
    margin: 0;
    border-bottom: 1px solid #ECECEC;
    font-family: graphic, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #333333;
}