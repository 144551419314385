.container {
    width: 100%;
}

.title {
    font-family: 'graphic';
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #1D1F23;
}

.block {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.rightBlock {
    b {
        font-family: 'graphic';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #6C6D6C;
    }
    p {
        font-family: 'graphic';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
        color: #000000;
        margin: 10px 0;
    }
}

.info {
    div:first-child span:first-child {
        border-radius: 14px;
        display: inline-block;
        width: 14px;
        height: 14px;
        background: linear-gradient(180deg, #73D389 0%, #4EB78B 100%);
        margin-right: 10px;
    }
    div:last-child span:first-child {
        border-radius: 14px;
        display: inline-block;
        width: 14px;
        height: 14px;
        background: linear-gradient(180deg, #705AFB 0%, #5769EB 100%);
        margin-right: 10px;
    }

    div span:last-child {
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: rgba(87, 100, 120, 0.73);
    }
}