.container {
  padding-left: 60px;
  max-width: 700px;
}

.backBtn {
  transform: translateX(-30px);
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.header {
  display: flex;
  align-items: center;
  padding-bottom: 18px;
  margin-bottom: 28px;
  border-bottom: 1px solid #E4E7EB;

  h2 {
    font-weight: 500;
    font-size: 18px;
    letter-spacing: -0.02em;
    color: #1D1F23;
    margin: 0;
  }
}

.block {
  margin-bottom: 24px;
  margin-top: 18px;
  &__title {
    font-family: 'graphic';
    font-size: 18px;
    margin-bottom: 16px;
    font-weight: 500;
    span {
      color: tomato;
    }
  }
}

.questionType {
  display: flex;
  align-items: center;
  i {
    height: 27px;
    margin-right: 18px;
  }
  em {
    font-style: normal;
    opacity: .8;
  }
}

.question {
  margin-bottom: 24px;
  position: relative;

  .positionBtns {
    position: absolute;
    right: 30px;
    top: 5px;
    transform: translateY(-70%);
    display: flex;
    align-items: center;
    gap: 8px;
    button {
      padding: 0;
      margin: 0;
      outline: none;
      border: none;
      background-color: transparent;
      cursor: pointer;
      color: #E7E7E6;

      svg {
        height: 26px;
        width: 26px;
        transition: .3s ease all;
      }
      &:hover {
        color: #000;
      }
    }
  }

  .deleteQuestionBtn {
    padding: 0;
    margin: 0;
    outline: none;
    border: none;
    background-color: transparent;
    position: absolute;
    right: 0;
    top: 5px;
    transform: translateY(-70%);
    cursor: pointer;
    svg {
      fill: #E7E7E6;
      transition: .3s ease all;
    }
    &:hover svg {
      fill: tomato;
    }
  }
}

.answers {
  margin-top: 21px;

  button {
    display: flex;
    align-items: baseline;
    margin-bottom: 21px;
    padding: 0;
    outline: none;
    border: none;
    background-color: transparent;
    font-size: 16px;
    color: #333333;
    font-family: 'graphic';
    cursor: pointer;
    span {
      display: flex;
      border-bottom: 1px solid #E7E7E6;
      padding-bottom: 10px;
    }
  }
}

.answer {
  display: flex;
  align-items: baseline;
  margin-bottom: 21px;
  position: relative;

  input {
    outline: none;
    font-family: 'graphic';
    width: 100%;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #E7E7E6;
    padding-bottom: 6px;
    padding-top: 6px;
    font-size: 16px;
    color: #333333;
    padding-right: 35px;
  }

  button {
    padding: 0;
    margin: 0;
    outline: none;
    border: none;
    background-color: transparent;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-70%);
    svg {
      fill: #E7E7E6;
      transition: .3s ease all;
    }
    &:hover svg {
      fill: tomato;
    }
  }

  .answerError {
    border-color: tomato;
  }
}

.footer {
  margin-top: 30px;
}
