.container {
  color: #333333;
  font-weight: 400;
  font-size: 16px;
  font-family: 'graphic';
  line-height: 17px;
  margin-bottom: 24px;

  h6 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 12px;
  }
}

.schedule {
  display: flex;
}

.times {

}

.time {
  display: flex;
  align-items: center;

  span {
    margin-left: 14px;
    width: 120px;
  }
}

.hours {
  display: flex;
  align-items: center;
  width: 290px;
}

.options {
  display: flex;
  flex-direction: column;
}

.checkbox {
  justify-self: right;
  margin-left: 41px;
}