.container {
}

.block {
  font-family: 'graphic';
  padding-bottom: 24px;
  position: relative;
  max-width: 320px;
  &__text {
    padding: 0;
    margin: 8px 0 0 0;
    font-size: 16px;
    color: #AFAFAB;
  }
  &__right-text {
    position: absolute;
    top: 43px;
    left: 173px;
    color: #1D1F23;
    font-size: 16px;
  }
  &__btn {
    position: absolute;
    top: 32px;
    left: 219px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.footer {
  margin-top: 16px;
  padding-top: 24px;
  border-top: 1px solid #E4E7EB;
  display: flex;
  button:first-child {
    margin-right: 18px;
  }
}

.modal {
  text-align: center;

  p {
    font-weight: 500;
    font-size: 22px;
    margin-top: -24px;
  }
  button {
    margin: 0 auto;
  }
}

.btn {
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.form {
  display: flex;
  min-width: 700px;
  align-items: center;
  & > div:nth-child(1)  {
    width: 40%;
  }
  & > div:nth-child(2)  {
    width: 25%;
  }
  & > div:nth-child(3)  {
    width: 35%;
  }
  button {
    transform: translateY(-7px);
  }
}
