.container {
  font-family: 'graphic';
  height: 100%;
  background: #FFFFFF;
  border-radius: 24px;
  padding: 28px;
}

.form {
  &__title {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #1D1F23;
  }
}

.formBlock {
  display: flex;
  gap: 15px;
}

.left {
  max-width: 734px;
  width: 100%;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #1D1F23;
    b {
      font-weight: 500;
    }
  }
}

.danger {
  color: #FA6262;
}

.writeIn {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #1D1F23;
  max-width: 200px;
  margin-bottom: 20px ;

}

.modal {
  text-align: center;
}
.modal p {
  margin-top: -20px;
}
.modal button {
  margin: 0 auto;
}

.removeList {
  gap: 16px;
  max-width: 734px;
  display: flex;
  align-items: flex-end;
}

.removeListInput {
}

.right {
  padding-top: 15px;
}

.exclusionListTitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.02em;
  display: block;
  color: #1D1F23;
}

.blackListWrapper {
  margin: 43px 0 35px;
  width: 49%;
}
@media screen and (max-width: 1140px) {
  .blackListWrapper {
    width: 100%;
  }
}

.table {
  th {
    font-family: 'graphic';
    font-weight: 500 !important;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    div {
      display: flex;
      align-items: center;
      span {
        margin-right: 2px;
      }
    }
  }
}