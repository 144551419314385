.container {
    display: flex;
    flex-direction: column;
    width: 300px;
    background-color: #1D1F23;
    user-select: none;
}

.logout {
    margin-top: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 82px 2px 28px;
}

.subscription {
    padding: 2px 62px 22px 18px;
    margin: 10px 0 0 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.countDown {
    color: rgb(115, 211, 137);
}

.subscription li {
    font-family: sf, serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    letter-spacing: -0.115976px;
    text-align: left;
}

.danger {
    color: tomato;
}

.subscription a {
    color: #fff;
    text-decoration: none;
}

.lang {
    padding-left: 38px;
    max-width: 80px;
}

/*@supports (position: sticky) {*/
/*    .logout {*/
/*        position: sticky;*/
/*        bottom: 20px;*/
/*    }*/
/*}*/

.logout span {
    min-width: 68px;
    font-family: sf, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 42px;
    color: #AFAFAB;
    letter-spacing: -0.115976px;
}

.logout span:hover {
    color: white
}

.logout img {
    margin-right: 14px;
}