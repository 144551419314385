.container{
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 24px;
    position: relative;
    padding: 8px;
    height: 100%;
}

.container h1 {
    margin: 0;
    font-family: graphic, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #333333;
    width: 100%;
    margin-bottom: 24px;
    padding-bottom: 18px;
    border-bottom: 1px solid #ECECEC;
}

.bonusButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bonusButton {
    align-self: flex-start;
}

.bonusButton button {
    cursor: pointer;
    border-radius: 8px;
    padding: 13px 48px;
    font-family: sf,serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    border: none;
    outline: none;
}

.dates{
    margin: 0 0 24px;
    display: flex;
    align-items: center;
    align-self: flex-start;
    width: 100%;
}
