.container {
    display: flex;
    align-items: center;
    position: relative;
    padding-top: 50px;
    padding-left: 100px;
}

.form-inner {}

.form {}