.container {
  position: relative;
  cursor: pointer;
  outline: none;
  overflow: hidden;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  border: 2px solid #E7E7E6;
  box-sizing: border-box;
  border-radius: 8px;

  min-width: 140px;
  width: 26%;
  height: 218px;

  * {
    transition: all .3s ease;
  }

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    fill: #E7E7E6;
    z-index: 2;
  }

  p {
    position: absolute;
    left: 0;
    right:  0;
    bottom: 0;
    font-weight: 500;
    font-family: 'graphic';
    font-size: 16px;
    padding: 14px 0;
    text-align: center;
    margin: 0;
    color: #fff;
    background-color: #1D1F23;
    width: 100%;
    z-index: 2;
  }

  &:hover {
    p {
      background-color: #64C889;
    }
    svg {
      fill: #64C889;
    }
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all .3s ease;
  }

  &:hover:after {
    background-color: rgba(#000, .7);
  }
}