.head {
  display: flex;
  align-items: center;
  padding-bottom: 21px;
  border-bottom: 1px solid #E4E7EB;
  margin-bottom: 28px;
  h2 {
    font-family: 'graphic';
    color: #1D1F23;
    font-weight: 500;
    font-size: 18px;
    margin: 0 15px 0 0;
  }
  p {
    font-size: 14px;
    color: #999999;
    margin: 0;
  }
}